import axios from 'axios';
import { Field, Formik, Form } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

const SettingAdd = () => {
  const navigate = useNavigate();
  const editor = useRef(null);  // Reference for JoditEditor

  const initialFormValues = {
    setting_name: "",
    setting_value: "",
  };

  const headers = {
    'x-access-token': localStorage.getItem('jwttoken') || "",
  };

  const callApiSettingAdd = async (values) => {
    try {
      const url = process.env.REACT_APP_API_URL + 'settings/addsetting';
      console.log(url);

      const response = await axios.post(url, values, { headers });
      if (response.status === 201) {
        alert("Setting Added Successfully");
        navigate('/settinglist');
      }
    } catch (error) {
      console.log('Error adding setting:', error.response ? error.response.data : error.message);
      if (error.response && error.response.status === 409) {
        alert('Setting already exists. Please try again.');
      } else {
        alert('Error adding Setting. Please try again.');
      }
    }
  };

  return (
    <div className="tp-faq__area pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8">
            <div className="tab-content" id="nav-tabContent">
              <div className="container mt-2 grid">
                <h3 className="text-center">Add Items</h3>

                <Formik
                  initialValues={initialFormValues}
                  onSubmit={async (values, { resetForm }) => {
                    const error = await callApiSettingAdd(values);
                    if (!error) {
                      resetForm();
                    }
                  }}
                >
                  {({ setFieldValue }) => (
                    <Form className="form-container">
                      <label htmlFor="setting_name">Item Name:</label>
                      <Field name="setting_name" type="text" />
                      <br /><br />
                      <label htmlFor="setting_value">Item Value:</label>
                      <JoditEditor
                        ref={editor}
                        value={initialFormValues.setting_value}
                        onBlur={newContent => setFieldValue('setting_value', newContent)} // Update form value on editor change
                        tabIndex={1}
                      />
                      <br /><br />
                      <button type="submit" className="btn btn-success mx-2">Add Items</button>
                      <button className="btn btn-primary">
                        <Link to={`/settinglist`} style={{ color: 'white', textDecoration: 'none' }}>Back to Item List</Link>
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingAdd;
