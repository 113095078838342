import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaBuffer,
  FaAlignJustify,
  FaBook,
  FaCalendarAlt,
} from "react-icons/fa";
import loadImage from "../images/giphy.gif";

const Dashboard = () => {
  const [terms_status, setTerms_status] = useState(null);
  const [terms_data , setTerms_data] = useState(null);

  const userid = window.localStorage.getItem("userid");
  const usertype = window.localStorage.getItem("usertype");

  const [userdetails, setUserdetails] = useState([]);
  const [loader, setLoader] = useState(true);

  const headers = {
    'x-access-token': localStorage.getItem('jwttoken')
  };

  const fetchUserDetails = async () => {
    try {
      setLoader(true);
      const url =
        process.env.REACT_APP_API_URL + "users/searchmernuser/" + userid;
      const response = await axios.get(url, { headers });

      const terms_status_URL = process.env.REACT_APP_API_URL + "users/checkTermsAndConditions/" + userid;
      const terms_status_response = await axios.get(terms_status_URL);
      setTerms_status(terms_status_response.data[0].terms_status);

      // console.log(terms_status_response);
      setUserdetails(response.data[0]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // http://localhost:4000/settings/:settingId
  const TermsAndCondition = async() => {
    try {
      const URL = process.env.REACT_APP_API_URL + "settings/fetch/termsandconditions";
      const response = await axios.get(URL);
      setTerms_data(response.data)
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  // http://localhost:4000/users/acceptTermsAndConditions/:userId
  const HandleAccept = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + "users/acceptTermsAndConditions/" + userid;
      const response = await axios.put(url);
      fetchUserDetails();
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    fetchUserDetails();
    TermsAndCondition();
  }, []);

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          {terms_status == 0 ? (
            <div class="modal" tabindex="-1" role="dialog" >
              <div class="modal-dialog mt-3" role="document" style={{ maxWidth: '75%', margin: 'auto'}}>
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Terms and Condition</h5>
                    {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button> */}
                  </div>
                  <div class="modal-body px-5"  style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <p>
                    {terms_data.map((term, index) => (
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{ __html: term.setting_value }}
                      />
                    ))}
                    </p>
                  </div>
                  <div class="modal-footer">             
                    <button type="button" onClick={HandleAccept} class="btn btn-primary">Accept and Proceed</button>
                    {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                  </div>
                </div>
              </div>
          </div>
          ) 
          : 
          (
              <div>
              <h2>Welcome, {userdetails && userdetails.name}</h2>
              <br></br>
              <div class="row">
              {usertype !== "mock" && (
                <>
                  <div class="col-sm-6 col-lg-6">
                    <Link to="/allclasses" className="link-success">
                      <div class="card text-white bg-flat-color-1">
                        <div class="card-body">
                          <div class="card-left pt-1 float-left">
                            <h3 class="mb-0 fw-r">
                              <span class="currency float-left mr-1">
                                Classes
                              </span>
                            </h3>
                            <br></br>
                            <br></br>
                            <p class="text-light mt-1 m-0">
                              Join your classes here
                            </p>
                          </div>

                          <div class="card-right float-right text-right">
                            <FaAlignJustify />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div class="col-sm-6 col-lg-6">
                    <Link to="/assignment" className="link-danger">
                      <div class="card text-white bg-flat-color-6">
                        <div class="card-body">
                          <div class="card-left pt-1 float-left">
                            <h3 class="mb-0 fw-r">
                              <span class="count float-left">Assignments</span>
                            </h3>
                            <br></br>
                            <br></br>
                            <p class="text-light mt-1 m-0">Live Now</p>
                          </div>

                          <div class="card-right float-right text-right">
                            <FaBook />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              )}

              <div class="col-sm-12 col-lg-12">
                <Link to="/mocks" className="link-danger">
                  <div class="card text-white bg-flat-color-3">
                    <div class="card-body">
                      <div class="card-left pt-1 float-left">
                        <h3 class="mb-0 fw-r">
                          <span class="count">Mocks</span>
                        </h3>
                        <br></br>
                        <p class="text-light mt-1 m-0">Coming Soon...</p>
                      </div>

                      <div class="card-right float-right text-right">
                        <FaBuffer />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div class="col-sm-6 col-lg-3">
            <Link to='/leaderboard' className='link-danger'>
              <div class="card text-white bg-flat-color-3">
                <div class="card-body">
                  <div class="card-left pt-1 float-left">
                    <h3 class="mb-0 fw-r">
                      <span class="count">Leaderboard</span>
                    </h3>
                    <br></br>
                    <p class="text-light mt-1 m-0">Coming Soon...</p>
                  </div>

                  <div class="card-right float-right text-right">
                    <FaBuffer />
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div class="col-sm-6 col-lg-3">
            <Link to='/mystatus' className='link-danger'>
            <div class="card text-dark bg-warning">
                <div class="card-body">
                  <div class="card-left pt-1 float-left">
                    <h3 class="mb-0 fw-r">
                      <span class="count">My Status</span>
                    </h3>
                    
                  </div>

                  <div class="card-right float-right text-right">
                    <FaBuffer />
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div class="col-sm-6 col-lg-3">
            <Link to='/quiz' className='link-danger'>
              <div class="card text-white bg-flat-color-2">
                <div class="card-body">
                  <div class="card-left pt-1 float-left">
                    <h3 class="mb-0 fw-r">
                      <span class="count">Quiz</span>
                    </h3>
                    <br></br>
                    <p class="text-light mt-1 m-0">Coming Soon...</p>
                  </div>

                  <div class="card-right float-right text-right">
                    <FaCalendarAlt />
                  </div>
                </div>
              </div>
            </Link>
          </div> */}
            </div>

            {/* <div className='row'>
          <div className='col-12'>
            <div class="alert alert-success" role="alert">
              Please, submit feedback after class to mark your attendance.
            </div>
          </div>
        </div> */}
          </div>) }
        </>
      )}
    </div>
  );
};

export default Dashboard;
