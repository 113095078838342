import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Modal, Button } from "react-bootstrap";
import loadImage from "../images/giphy.gif";

const notifyData = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const notifySuccess = () => toast.success("Syllabus deleted Successfully!", notifyData);
const notifyError = () => toast.error("Syllabus not deleted!", notifyData);

const SyllabusList = () => {
  const [syllabusList, setSyllabusList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const callApiSyllabusList = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      setLoader(true);
      const url = process.env.REACT_APP_API_URL + 'syllabus/getAllActiveSyllabus';
      const response = await axios.get(url, { headers });
      setSyllabusList(response.data);
      setLoader(false);


    } catch (error) {
      console.log(error);
      setLoader(false);

    }
  };

  useEffect(() => {
    callApiSyllabusList();
  }, []);

  const deleteSyllabus = async (id) => {
    setLoader(true);
    const url = process.env.REACT_APP_API_URL + `syllabus/delete/${id}`;
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.put(url, { headers });
      setLoader(false);
      if (response.status === 200) {
        notifySuccess();
        callApiSyllabusList();
      } else {
        notifyError();
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShowConfirmation(true);
  };

  const handleDeleteItem = () => {
    deleteSyllabus(deleteId);
    setShowConfirmation(false);
  };

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Syllabus Name</th>
                <th>Course ID</th>
                <th>Task Array</th>
              </tr>
            </thead>
            <tbody>
              {syllabusList &&
                syllabusList.map((item, index) => (
                  <tr key={item.syllabusid}>
                    <td>{index + 1}</td>
                    <td>{item.syllabusname}</td>
                    <td>{item.courseid}</td>
                    <td>{item.taskarray}</td>
                    <td>
                      <Link to={`/Syllabusedit/${item.syllabusid}`} className='btn btn-warning'>
                        Edit
                      </Link>
                    </td>
                    <td>
                      <button onClick={() => handleClickDelete(item.syllabusid)} className='btn btn-danger'>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <Modal show={showConfirmation} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this syllabus?</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleDeleteItem}>
                OK
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <ToastContainer />
          <br />
          <Link to="/syllabusAdd" className='btn btn-primary'>Create New Syllabus</Link>
        </>
      )}
    </div>
  );
};

export default SyllabusList;