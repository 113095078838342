import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadImage from "../images/giphy.gif";

const notifyData = {
  position: "top-center",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const errNotify = () => toast.error("User not Activated!", notifyData);
const deleteNotify = () => toast.success("User deleted Successfully!", notifyData);
const errDeleteNotify = () => toast.error("User not Deleted!", notifyData);

const SettingList = () => {
  const [settingList, setSettingList] = useState([]);
  const [loader, setLoader] = useState(true);

  const callApiSettingList = async () => {
    try {
      setLoader(true);
      const headers = {
        "x-access-token": localStorage.getItem("jwttoken"),
      };
      const url = `${process.env.REACT_APP_API_URL}settings/allsettings`;
      const response = await axios.get(url, { headers });
      setSettingList(response.data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load settings.", notifyData);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    callApiSettingList();
  }, []);

  const headers = {
    'x-access-token': localStorage.getItem('jwttoken') || "",
  };

  const handleDelete = async (setting_id) => {
    try {
      const confirm = window.confirm("Are you sure you want to delete?");
      if (!confirm) return;

      const url = process.env.REACT_APP_API_URL + "settings/deletesetting/" + setting_id;
      const response = await axios.delete(url, { headers });
      console.log('Delete URL:', url);
      console.log('Setting ID:', setting_id);
          
      if (response.status === 200) {
        deleteNotify();
        callApiSettingList();
      } else {
        errDeleteNotify();
      }
    } catch (error) {
      console.error("Error: " + error);
      errDeleteNotify();
    }
  };

  return (
    <div>
      {loader ? (
        <div className="col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No</th>
                <th className="bg-primary-subtle">Setting ID</th>
                <th className="bg-warning-subtle">Setting Name</th>
                <th className="bg-success-subtle">Setting Value</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {settingList && settingList.map((item, index) => (
                <tr key={index + item.setting_id}>
                  <td>{index + 1}</td>
                  <td className="bg-primary-subtle">{item.setting_id}</td>
                  <td className="bg-warning-subtle">{item.setting_name}</td>
                  <td className="bg-success-subtle" dangerouslySetInnerHTML={{ __html: item.setting_value}}></td>
                  <td>
                    <Link
                      to={`/updatesetting/${item.setting_id}`}
                      className="btn btn-warning m-1"
                    >
                      Edit
                    </Link>
                    <button
                      className="btn btn-danger m-1"
                      onClick={() => handleDelete(item.setting_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <Link to="/addsetting" className="btn btn-primary">
            Create New Item
          </Link>
          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default SettingList;