import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import loadImage from "../images/giphy.gif";

const Assignment = () => {
  const [classList, setClassList] = useState([]);
  const [loader, setLoader] = useState(true);
  const date = new Date();

  const callAssignmentList = async () => {
    try {
      setLoader(true);
      const url = process.env.REACT_APP_API_URL + 'assignments/showalladmin';
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
  
      // Get registered courses from local storage
      let registeredCourses = localStorage.getItem('registered_courses');
      
      // If registeredCourses is a plain string (e.g., comma-separated list)
      if (registeredCourses && !registeredCourses.startsWith('[')) {
        registeredCourses = registeredCourses.split(',');
      } else {
        registeredCourses = JSON.parse(registeredCourses);
      }
  
      // Filter assignments based on registered courses
      const filteredAssignments = response.data.filter(item =>
        registeredCourses.includes(item.courseid)
      );
  
      setClassList(filteredAssignments);
      setLoader(false);
    }
    catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  
  useEffect(() => {
    callAssignmentList();
  }, []);

  return (
    <div>
      {loader ? (
        <div className="col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Task</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {classList.length > 0 &&
                classList.map((item, index) => (
                  <tr key={index + item.taskid}>
                    <td>{index + 1}</td>
                    <td>{item.taskname}</td>
                    <td>
                      <Link
                        to={`/assignment/${item.taskid}`}
                        className="btn btn-primary"
                      >
                        Open Assignment
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Assignment;
