import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadImage from "../images/giphy.gif";

const UsersList = () => {
  const [userList, setUserList] = useState([]);
  const [loader, setloader] = useState(true);

  const headers = {
    'x-access-token': localStorage.getItem('jwttoken')
  };

  const callApiExamsList = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      setloader(true);
      const url = process.env.REACT_APP_API_URL + 'users/allmernusers';
      const response = await axios.get(url, { headers });
      //console.log(response);
      setUserList(response.data);
      setloader(false);

    } catch (error) {
      console.log(error);
      setloader(false);
    }
  };

  useEffect(() => {
    callApiExamsList();
  }, []);

  const deleteUser = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    var val = confirm("Sure you want to delete user?");
    if (val === true) {
      try {
        const url = process.env.REACT_APP_API_URL + 'users/removeuser/' + id;
        const response = await axios.delete(url, { headers });
        //console.log(response);
        alert("User deleted");
      } catch (error) {
        console.log(error)
      }
    } else {
      alert("User not Deleted");
    }
    callApiExamsList();
  };

  const activateUser = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    var val = confirm("Sure you want to activate user?");
    if (val === true) {
      const url = process.env.REACT_APP_API_URL + "users/activateuser/" + id;
      const response = await axios.put(url, { headers });
      // console.log(response);
      alert("User Activated");
    } else {
      alert("User not Activated");
    }
    callApiExamsList();
  };

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={LoadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Whatsapp</th>
                <th className="bg-warning-subtle">UserId</th>
                <th className="bg-warning-subtle">Password</th>
                <th>City</th>
                <th>College</th>
                <th>Passing</th>
                <th>Branch</th>
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.map((item, index) => (
                  <tr key={index + item.exam_id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>{item.whatsapp_mobile}</td>
                    <td className="bg-warning-subtle">{item.userid}</td>
                    <td className="bg-warning-subtle">{item.password}</td>
                    <td>{item.city}</td>
                    <td>{item.college}</td>
                    <td>{item.passing_year}</td>
                    <td>{item.branch}</td>
                    {/* <td>
                  <Link to={`/useredit/${item.userid}`} className='btn btn-warning'>Edit</Link>
                </td>
                <td>
                  <button onClick={() => deleteUser(item.userid)} className='btn btn-primary'>Delete User</button>
                </td>
                <td>
                  <button onClick={() => activateUser(item.userid)} className='btn btn-success'>Activate User</button>
                </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
          <br></br>
          {/* <Link to="/useradd" className='btn btn-primary'>Create New User</Link> */}
        </>
      )}
    </div>
  );
};

export default UsersList;
