import React, { useState, useEffect, useRef } from 'react'
import header from '../images/letterhead_top.png';
import footer from '../images/letterhead_bottom.png';
import sign from '../images/sign.png';
import sign2 from '../images/rounak_sign.png';
import mca from '../images/mca.png';
import aicte from '../images/aicte.png';
import new_seal from '../images/seal_new.png';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import { Button } from "react-bootstrap";
import headernew from '../images/letterhead_top_pvtltd.png';
import footernew from '../images/letterhead_bottom_pvtltd.png';

const ExperienceLetterMBA = () => {

    const { userid } = useParams();
    console.log('userid - ', userid);

    const [userDetail, setUserDetail] = useState([]);
    const navigate = useNavigate();
    const backButtonRef = useRef(null);

    const fetchUserDetail = async () => {
        try {
            const headers = {
                'x-access-token': localStorage.getItem('jwttoken')
            };
            const response = await axios.get(process.env.REACT_APP_API_URL + 'course/userDetails/' + userid, { headers });
            setUserDetail(response.data);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        fetchUserDetail();
    }, []);

    // const handlePrint = () => {
    //     // Hide the back Button
    //     if (backButtonRef.current) {
    //         backButtonRef.current.style.display = 'none';
    //     }

    //     // Triger the Print dailog
    //     window.print();

    //     // show the back Button again after printing
    //     if (backButtonRef.current) {
    //         backButtonRef.current.style.display = 'flex';
    //     }

    // };

    const root=document.querySelector("#root");
    const bodyRef = useRef(document.body);
    const printContainerRef = useRef(null);
  
    const printDiv = () => {
      const clone = printContainerRef.current.cloneNode(true);
      bodyRef.current.innerHTML = '';
      bodyRef.current.appendChild(clone);
      window.print();
      bodyRef.current.innerHTML = '';
      bodyRef.current.appendChild(root);     
    };

    return (
        <div className='full-screen-print'>

            <div ref={backButtonRef} className="on-print button-container">
                <Button onClick={() => navigate(-1)} variant="secondary" >
                    Back
                </Button>
                <Button onClick={printDiv} variant="primary">
                    Generate PDF
                </Button>
            </div>

            <div className='print-container vh-100 overflow-hidden' ref={printContainerRef}>
            <div><img src={headernew} alt='header' /></div>

            <div className='py-5 px-5'>

                <h3 className='certi-header'>HashedBit Innovations - Management Internship Letter</h3>

                <div>
                    <br></br><br></br>
                    {/* Date: 18th March 2024
                    <br></br><br></br> */}


                    <span className='fw-bold'>
                        Name - {userDetail.name}<br></br>
                        Address - {userDetail.city}<br></br>
                        College – {userDetail.college}<br></br>
                        Email - {userDetail.email}<br></br>
                        <br></br>
                    </span>


                    <span className='fw-bold'>
                        Emp. ID – {userDetail.employeeid}<br></br>
                        Date – {userDetail.enddate}<br></br>
                        Certificate No - {userDetail.certificateid}
                        <br></br><br></br>
                    </span>


                    This is to certify that {userDetail.gender === 'male' ? 'Mr.' : 'Ms.'} {userDetail.name} from {userDetail.college} has successfully completed {userDetail.gender === 'male' ? 'his' : 'her'} internship in Digital Marketing Domain for {userDetail.duration} from {userDetail.startdate} to {userDetail.enddate} as Management Intern.
                    <br></br><br></br>

                    During {userDetail.gender === 'male' ? 'his' : 'her'} tenure, we found {userDetail.gender === 'male' ? 'him' : 'her'} to be self-motivated, sincere and hard-working. {userDetail.gender === 'male' ? 'His' : 'Her'} efforts and performance on the assigned tasks are appreciable. We wish {userDetail.gender === 'male' ? 'him' : 'her'} the best for {userDetail.gender === 'male' ? 'his' : 'her'} future endeavours.
                    <br></br><br></br><br></br>

                    <b>For HashedBit Innovations Pvt Ltd</b>
                    <br></br>

                    <div className='row'>
                        <div className='col-4'>
                            <img src={sign2} alt='header' style={{ width: '150px' }} />
                        </div>
                        <div className='col-4'>

                        </div>
                        <div className='col-4'>
                            <img src={sign} alt='header' style={{ width: '150px' }} />

                        </div>
                    </div>

                    <div className='row d-print-none'>
                        <div className='col-4'>
                            <span className='fw-bold'>Program Coordinator
                                <br></br>
                                HashedBit Innovations Pvt Ltd
                            </span>
                        </div>
                        <div className='col-4'>

                        </div>
                        <div className='col-4'>
                            <span className='fw-bold'>HR Manager
                                <br></br>
                                HashedBit Innovations Pvt Ltd
                            </span>
                        </div>
                    </div>


                    <div className='row py-4'>
                        <div className='col-4'>
                            <img src={new_seal} alt='header' style={{ width: '100px' }} />
                        </div>
                        <div className='col-4 py-4 text-center'>
                            <img src={mca} alt='header' style={{ width: '130px' }} />
                        </div>
                        <div className='col-4 text-end'>
                            <img src={aicte} alt='header' style={{ width: '100px' }} />
                        </div>
                    </div>

                    {/* <img src={sign} alt='header' style={{ width: '150px' }} />
                    <img src={new_seal} alt='header' style={{ width: '130px' }} className='seal' />
                    <br></br>
                    <span className='fw-bold'>HR Manager
                        <br></br>
                        HashedBit Innovations Pvt Ltd
                    </span> */}
                    {/* <br></br><br></br><br></br><br></br><br></br> */}


                </div>

            </div>
            <div className='footer-bottom text-center'>
                <span>To verify the certificate, visit - <a href='https://www.hashedbit.com/verification' target='_blank'>https://www.hashedbit.com/verification</a><br></br><br></br></span>
                <img src={footernew} alt='header' /></div>
        </div>
        </div>
    )
}

export default ExperienceLetterMBA
