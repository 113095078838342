import React, { useRef, useState } from "react";
import axios from "axios";
import { Formik, Field, Form, useField } from "formik";
import loadImage from "../images/giphy.gif";
import Stars from "react-stars";
import JoditEditor from "jodit-react";

const FormFeedback = (props) => {
  const editor = useRef(null);
  const { classid } = props;

  const userid = localStorage.getItem("userid");

  const [apistatus, setApistatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const sendFeedback = async (values) => {
    try {
      //console.log(values)
      const url = process.env.REACT_APP_API_URL + "attendance/sendfeedback/"; const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.put(url, values, { headers });
      //console.log(response);
      if (response.status === 200) {
        setApistatus(true);
        setLoader(false);
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setLoader(false);
    }
  };

  const initObj = {
    rating: "",
    content_quality: "",
    speed_language: "",
    topic_understanding: "",
    feedback: "",
    topics: "",
    userid: userid,
    classid: classid,
  };

  return (
    <>
      <div className="row">
        <div className="col-12 px-4">
          <Formik
            initialValues={initObj}
            onSubmit={async (values, { resetForm }) => {
              //console.log(values);
              const { error } = await sendFeedback(values);
              if (!error) {
                resetForm();
              }
            }}
          >
            <Form>
              <br></br>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Quality of Content
                </label>
                <div className="col-sm-7">
                  <Field
                    name="content_quality"
                    type="number"
                    className="form-control"
                    render={({ field, form }) => (
                      <Stars
                        {...field}
                        size={30}
                        half={true}
                        value={field.value}
                        onChange={(value) =>
                          form.setFieldValue("content_quality", value)
                        }
                      />
                    )}
                    min="1"
                    max="5"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Class Speed and Language
                </label>
                <div className="col-sm-7">
                  <Field
                    name="speed_language"
                    type="number"
                    className="form-control"
                    render={({ field, form }) => (
                      <Stars
                        {...field}
                        size={30}
                        half={true}
                        value={field.value}
                        onChange={(value) =>
                          form.setFieldValue("speed_language", value)
                        }
                      />
                    )}
                    min="1"
                    max="10"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Understanding of Topics
                </label>
                <div className="col-sm-7">
                  <Field
                    name="topic_understanding"
                    type="number"
                    className="form-control"
                    render={({ field, form }) => (
                      <Stars
                        {...field}
                        size={30}
                        half={true}
                        value={field.value}
                        onChange={(value) =>
                          form.setFieldValue("topic_understanding", value)
                        }
                      />
                    )}
                    min="1"
                    max="10"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Overall Rating
                </label>
                <div className="col-sm-7">
                  <Field
                    name="rating"
                    type="number"
                    className="form-control"
                    render={({ field, form }) => (
                      <Stars
                        {...field}
                        size={30}
                        half={true}
                        value={field.value}
                        onChange={(value) =>
                          form.setFieldValue("rating", value)
                        }
                      />
                    )}
                    min="1"
                    max="10"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Name of few topics taught in class
                </label>
                <div className="col-sm-7">
                  <Field
                    name="topics"
                    type="text"
                    className="form-control"
                    min="1"
                    max="10"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Any feedback suggestions
                </label>
                <div className="col-sm-7">
                  <Field
                    name="feedback"
                    type="text"
                    className="form-control"
                    render={({ field, form }) => (
                      <JoditEditor
                        {...field}
                        ref={editor}
                        value={field.value}
                        id="content"
                        className="form-control"
                        name="content"
                        onChange={(value) =>
                          form.setFieldValue("feedback", value)
                        }
                      />
                    )}
                    min="1"
                    max="10"
                    required
                  />
                </div>
              </div>

              {/* <div className="form-group row">
                                <span id="my-radio-group" className="col-sm-5 col-form-label">Do you have laptop?</span>
                                <div className='col-sm-7'>
                                    <span role="group" aria-labelledby="my-radio-group">
                                        <label>
                                            <Field type="radio" name="islaptop" value="One" className="form-check-input" />
                                            Yes
                                        </label>
                                        <label>
                                            <Field type="radio" name="islaptop" value="Two" className="form-check-input" />
                                            No
                                        </label>
                                    </span>
                                </div>
                            </div> */}

              <Field name="classid" type="text" className="form-control" hidden />
              <Field name="userid" type="text" className="form-control" hidden />

              <br></br>
              <button type="submit" className="btn btn-success">
                Submit Feedback
              </button>

              <br></br>
              <br></br>
            </Form>
          </Formik>
        </div>
      </div>

      {apistatus && (
        <div className="alert alert-success" role="alert">
          You are successfully registered.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          Please, try after sometime or try again with correct values.
        </div>
      )}

      {loader && (
        <div className="loading">
          <img src={loadImage} alt="Loader" />
        </div>
      )}
    </>
  );
};

export default FormFeedback;
