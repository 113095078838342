import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import loadImage from "../images/giphy.gif";

const RegisteredUserUn = () => {
  const [userList, setUserList] = useState([]);
  const [filterCourseId, setFilterCourseId] = useState("");
  const [loader, setLoader] = useState(true);
  const { userId } = useParams();
  const headers = {
    "x-access-token": localStorage.getItem("jwttoken"),
  };

  const fetchUserList = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "course/getuniqueregisteredusers",
        { headers }
      );

      setUserList(response.data);
      setLoader(false);

      // Assuming your API returns an array of user data
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, [userId]);

  const filteredUserList = filterCourseId
    ? userList.filter((user) => user.courseid.toLowerCase() === filterCourseId)
    : userList;
  //filteredUserList = filteredUserList.reverse()

  const [startdate, setStartDate] = useState("");
  const [duration, setDuration] = useState("");
  const [userid, setUserid] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showMbaModal, setShowMbaModal] = useState(false);
  const [formData, setFormData] = useState({
    heading: '',
    meetingLink: '',
    date: '',
    time: '',
    email:'',
    userid:'',
  });
  const [showMailMngIModal, setShowMailMngIModal] = useState(false);



  const handleClose = () => {
    setShowModal(false);
    setShowMbaModal(false);
    setShowMailMngIModal(false);
  };

  const convertTechIntern = (userid) => {
    setUserid(userid);
    setShowModal(true);
  };

  const apiConvertTechIntern = async () => {
    let obj = {};
    obj.userid = userid;
    obj.duration = duration;
    obj.startdate = startdate;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "users/convertTechIntern",
        obj,
        { headers }
      );
      fetchUserList();
      setShowModal(false);
    } catch (error) {
      console.error("Error converting user", error);
    }
  };

  const convertMbaIntern = (userid) => {
    setUserid(userid);
    setShowMbaModal(true);
  };

  const apiConvertMbaIntern = async () => {
    let obj = {};
    obj.userid = userid;
    obj.duration = duration;
    obj.startdate = startdate;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "users/convertMbaIntern",
        obj,
        { headers }
      );
      fetchUserList();
      setShowMbaModal(false);
    } catch (error) {
      console.error("Error converting user", error);
    }
  };


  const apiMailTechInvite = async (userid, email) => {
    console.log('send invite on mail')
    let obj = {};
    obj.userid = userid;
    obj.email = email;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "course/mailtechinvite",
        obj,
        { headers }
      );
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const apiWhatsAppTechInvite = async (userid, mobile) => {
    let mobiletemp = mobile.substr(mobile.length - 10)
    const phoneNumber = '+91' + mobiletemp; // Replace with your phone number
    let fulldate = new Date();
    fulldate.setDate(fulldate.getDate() + 1);
    const day = fulldate.getDay();
    if (day == 7) {
      fulldate.setDate(fulldate.getDate() + 2);
    }
    if (day == 0) {
      fulldate.setDate(fulldate.getDate() + 1);
    }

    const month = fulldate.toLocaleString('default', { month: 'long' });
    let date = fulldate.getDate();

    const message = `Hi,

We have received your details for the tech internship at HashedBit Innovations. To qualify for the internship, you have to attempt a short online screening test consisting of 30 MCQs (JavaScript, React).

Details of your online test will be shared in the exam meeting. You have to join a laptop with camera and screen sharing working. Exam meeting details are as follows.

${month} ${date} · 6:00 – 7:00pm
Video call link:- https://meet.google.com/gwa-ybnx-qdu

For any query, plz call/whatsapp Sushma (09669787936) or Rounak (7879262388)`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    // console.log(whatsappLink)
    window.open(whatsappLink, '_blank').focus();
  };



  const apiWhatsAppTechSelected = async (userid, mobile) => {
    let mobiletemp = mobile.substr(mobile.length - 10)
    const phoneNumber = '+91' + mobiletemp; // Replace with your phone number

    const message = `Hi,

Congratulations ! You have cleared the online test for joining HashedBit Technical Internship.

Please, check your registered mail for details.

For any query, please, revert here.`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    // console.log(whatsappLink)
    window.open(whatsappLink, '_blank').focus();
  };



  const apiWhatsAppTechNotSelected = async (userid, mobile) => {
    let mobiletemp = mobile.substr(mobile.length - 10)
    const phoneNumber = '+91' + mobiletemp; // Replace with your phone number

    const message = `Hi,

We regret to inform you that you were not able to clear the screening test for HashedBit Internship.

Looking into your efforts, we would like to offer you an opportunity for you to join our Training cum Internship program.

Program Fee - INR 2500 (Registration Fee 1000 to be paid at start and rest on completion)
Duration - 2 Months

Benefits :-
1) Internship Offer Letter and Experience Letter
2) Online training on MERN Stack with Mentor sessions
3) Live Projects Internship Opportunity

To Join, please, reply by mail or call/whatsapp - 9669787936.`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    // console.log(whatsappLink)
    window.open(whatsappLink, '_blank').focus();
  };




  const apiWhatsAppTechOnboarding = async (userid, mobile) => {
    let mobiletemp = mobile.substr(mobile.length - 10)
    const phoneNumber = '+91' + mobiletemp; // Replace with your phone number

    const message = `Hi,

Internship Start Date - As per your preference.

Onboarding ​& Projects Bench Group​ meeting details :-
Daily - 5:00 – 5:30pm
Google Meet Video call link: https://meet.google.com/jsi-envy-nbu

​Please, join this whatsapp group (Projects Bench Group) - https://chat.whatsapp.com/GzeEN6J2Wuc11EVhlnf60b

Tech Internship ​Process :-
Joining ​Projects Bench Group.
Join Onboarding Meeting as mentioned above.
Offer Letter and Learning Portal access on the first day of joining.
Daily Projects Bench Group meeting as mentioned above.
Basic learning and assignments of MERN.
Live Project allotment.
Joining Projects' Team's WhatsApp group
Daily Project Team Meetings and tasks.


​Note - Please, join the onboarding meeting compulsorily whenever you want to start your internship. Date of joining the onboarding meeting will be the start date of your internship.


Also, plz like and follow company social media for updates.
LinkedIn - https://www.linkedin.com/company/hashedbit-innovations/
Instagram - https://www.instagram.com/hashedbit/
Twitter - https://twitter.com/HashedBit


You can visit the company website and linkedin page for more details. For any queries related to internship, feel free to call/mail/whatsapp - Point of Contact - Sushma Kumari (9669787936)`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    // console.log(whatsappLink)
    window.open(whatsappLink, '_blank').focus();
  };


  const markfeedue = async (userid) => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + 'course/markfeedue', { userid, feestatus: 'Due' }, { headers });
      fetchUserList();
    } catch (error) {
      console.error('Error updating document status:', error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const MailMngmntInvite = (userid, email) => {
    setUserid(userid);
    setFormData({ ...formData, userid });
    setFormData({ ...formData, email });
    setShowMailMngIModal(true);
  };

  const apiMailMngmntInvite = async () => {
    //task - take date and time from user by modal.
    console.log('send invite on mail for management')
    let obj = {
      email: formData.email,
      heading: formData.heading,
      meetingLink: formData.meetingLink,
      date: formData.date,
      time: formData.time,
    };
  
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "course/mailmngmntinvite",
        obj,
        { headers }
      );
      console.log("Management invite email sent successfully:", response.data);
      handleClose();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const apiWhatsAppMngmntInvite = async (userid, mobile) => {
    //task - take date and time from user by modal.
    let mobiletemp = mobile.substr(mobile.length - 10)
    const phoneNumber = '+91' + mobiletemp; // Replace with your phone number
    let fulldate = new Date();
    fulldate.setDate(fulldate.getDate() + 1);
    const day = fulldate.getDay();
    if (day == 7) {
      fulldate.setDate(fulldate.getDate() + 2);
    }
    if (day == 0) {
      fulldate.setDate(fulldate.getDate() + 1);
    }

    const month = fulldate.toLocaleString('default', { month: 'long' });
    let date = fulldate.getDate();

    const message = `Hi,

I am Sushma from HashedBit Innovations.

We have received your details for the management internship at HashedBit Innovations. To qualify for the internship, you have to attend a short discussion with our panel.

You have to join a laptop/mobile with a camera. Discussion meeting details are as follows.

May 12 · 4:00 – 5:00pm
Video call link: https://meet.google.com/wkk-ogta-yhd

For any query, plz call/whatsapp Sushma (09669787936)`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    // console.log(whatsappLink)
    window.open(whatsappLink, '_blank').focus();
  };


  const addToExam = async (userid, name, email, mobile) => {
    //task - take date and time from user by modal.
    console.log('send invite on mail for management')

    const url = process.env.REACT_APP_EXAM_API_URL + "users/adduser";

    const values = {
      name: name,
      email: email,
      mobile: mobile,
      password: "Password@2024"
    }

    try {
      const response = await axios.post(url, values);
      console.log(response);
      if (response.status === 201) {
        setLoader(false);
      }
    }
    catch (error) {
      setLoader(false);
      console.log('Error in saving user to exam db')
    }

  };

  const apiMarkSelected = async (userid) => {
    let obj = {};
    obj.userid = userid;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "course/markselected",
        obj,
        { headers }
      );
      fetchUserList();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const apiMarkNotSelected = async (userid) => {
    let obj = {};
    obj.userid = userid;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "course/marknotselected",
        obj,
        { headers }
      );
      fetchUserList();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const apiOnboarding = async (userid) => {
    let obj = {};
    obj.userid = userid;
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "course/onboarding",
        obj,
        { headers }
      );
      fetchUserList();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="loader" />
        </div>
      ) : (
        <>
          <h2 className="mb-4">User Details</h2>

          <select
            value={filterCourseId}
            onChange={(e) => setFilterCourseId(e.target.value)}
            className="p-2 mb-4"
          >
            <option value="">All Course IDs</option>
            <option value="mern">MERN</option>
            <option value="plac">PLAC</option>
            <option value="mbai">MBAI</option>
            <option value="mbap">MBAP</option>
            <option value="techintern">TechIntern</option>
            <option value="tech">Tech</option>
            <option value="mock">Mock</option>
            <option value="inte">Inte</option>
          </select>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User ID</th>
                <th>Date</th>
                <th className="bg-warning-subtle">Username</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th className="bg-warning-subtle">Password</th>
                <th>WhatsApp</th>
                <th>City</th>
                <th>Hometown</th>
                <th>College</th>
                <th>University</th>
                <th>Passing Year</th>
                <th>Branch</th>
                <th>Course ID</th>
                <th>Where do you hear about us?</th>
                <th colSpan={2}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUserList.map((user, index) => (
                <tr key={index + user.user_id}>
                  <td>{index + 1}</td>
                  <td>
                    <Link
                      to={`/users_registered_detail/${user.user_id}`}
                      className="btn btn-outline-info"
                      data-mdb-ripple-color="light"
                    >
                      {user.user_id}
                    </Link>
                  </td>
                  <td>{user.regdate}</td>
                  <td className="bg-warning-subtle">{user.username}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.mobile}</td>
                  <td className="bg-warning-subtle">{user.password}</td>
                  <td>{user.whatsapp}</td>
                  <td>{user.city}</td>
                  <td>{user.hometown}</td>
                  <td>{user.college}</td>
                  <td>{user.university}</td>
                  <td>{user.passing_year}</td>
                  <td>{user.branch}</td>
                  <td>{user.courseid}</td>
                  <td>{user.referredby}</td>
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => apiMailTechInvite(user.user_id, user.email)}
                    >
                      Mail Tech Invite
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => apiWhatsAppTechInvite(user.user_id, user.mobile)}
                    >
                      WhatsApp Tech Invite
                    </button>
                  </td>

                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => addToExam(user.user_id, user.name, user.email, user.mobile)}
                    >
                      Add to Exam
                    </button>
                  </td>

                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => apiWhatsAppTechSelected(user.user_id, user.mobile)}
                    >
                      WhatsApp Tech Selected
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      disabled={user.reg_courses_regstatus === "selected"}
                      onClick={() => apiMarkSelected(user.user_id)}
                    >
                      Mark Tech Selected
                    </button>
                  </td>


                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => apiWhatsAppTechNotSelected(user.user_id, user.mobile)}
                    >
                      WhatsApp Tech Not Selected
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      disabled={user.reg_courses_regstatus === "notselected"}
                      onClick={() => apiMarkNotSelected(user.user_id)}
                    >
                      Mark Tech Not Selected
                    </button>
                  </td>

                  <td>
                    <button
                      className="btn btn-warning"
                      disabled={user.reg_courses_regstatus !== "selected"}
                      onClick={() => apiOnboarding(user.user_id)}
                    >
                      Send Tech Onboarding
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-warning"
                      disabled={user.reg_courses_regstatus !== "selected"}
                      onClick={() => apiWhatsAppTechOnboarding(user.user_id, user.mobile)}
                    >
                      WhatsApp Tech Onboarding
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-success"
                      // disabled={user.courseid === "emp_techintern"}
                      onClick={() => convertTechIntern(user.user_id)}
                    >
                      Convert to Tech Intern
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      // disabled={user.courseid === "emp_techintern"}
                      onClick={() => markfeedue(user.user_id)}
                    >
                      Mark Fee Due
                    </button>
                  </td>


                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => MailMngmntInvite(user.user_id, user.email)}
                    >
                      Mail Management Invite
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-info"
                      disabled
                      onClick={() => apiWhatsAppMngmntInvite(user.user_id, user.mobile)}
                    >
                      WhatsApp Management Invite
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-success"
                      disabled={user.courseid === "emp_mbaintern"}
                      onClick={() => convertMbaIntern(user.user_id)}
                    >
                      Convert to MBA Intern
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Modal show={showModal}>
            <Modal.Header>
              <Modal.Title>Activate Tech Intern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <label>Start Date:</label>
              <input
                type="date"
                placeholder="Start Date"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <br></br>
              <label>Duration:</label>
              <input
                type="text"
                placeholder="Duration"
                onChange={(e) => setDuration(e.target.value)}
              />
              <br></br>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => apiConvertTechIntern()}
                variant="primary"
                style={{ marginLeft: "10px" }}
              >
                Activate Tech Intern
              </Button>
            </Modal.Footer>
          </Modal>

          {/* convert mba intern modal */}
          <Modal show={showMbaModal}>
            <Modal.Header>
              <Modal.Title>Activate MBA Intern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <label>Start Date:</label>
              <input
                type="date"
                placeholder="Start Date"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <br></br>
              <label>Duration:</label>
              <input
                type="text"
                placeholder="Duration"
                onChange={(e) => setDuration(e.target.value)}
              />
              <br></br>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => apiConvertMbaIntern()}
                variant="primary"
                style={{ marginLeft: "10px" }}
              >
                Activate MBA Intern
              </Button>
            </Modal.Footer>
          </Modal>

          {/* mail management invite modal */}
          <Modal show={showMailMngIModal}>
            <Modal.Header>
              <Modal.Title>Mail management Invite</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <label>Heading:</label>
              <input
                type="text"
                placeholder="Heading"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
              />
              <br />
              <label>Meeting:</label>
              <input
                type="url"
                placeholder="Meeting Link"
                name="meetingLink"
                value={formData.meetingLink}
                onChange={handleChange}
              />
              <br />
              <label>Date:</label>
              <input
                type="date"
                placeholder="Date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              />
              <br />
              <label>Time:</label>
              <input
                type="time"
                placeholder="Time"
                name="time"
                value={formData.time}
                onChange={handleChange}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => apiMailMngmntInvite()}
                variant="primary"
                style={{ marginLeft: "10px" }}
              >
                Send Invite
              </Button>
            </Modal.Footer>
          </Modal>

        </>
      )}
    </div>
  );
};

export default RegisteredUserUn;
