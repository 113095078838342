import React, { FC } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from "formik";
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notifydata = {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}
const successNotify = () => toast.success("User Added Successfully!", notifydata);
const errNotify = () => toast.error("User not Added!", notifydata);
const UserAdd = () => {
  const navigate = useNavigate();
  const callApiUserAdd = async (values) => {
    const url = process.env.REACT_APP_API_URL + 'users/adduser';
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.post(url, values, { headers });
      successNotify();
      setTimeout(() => {
        navigate('/userslist');
      }, 1200);
    } catch (error) {
      errNotify();
    }

  }

  return (
    <div>

      <h4 className=''>Create New User</h4>
      <Formik
        initialValues={{ name: "", email: "", mobile: "", password: "" }}
        onSubmit={async (values) => {
          // console.log(values);
          callApiUserAdd(values);
        }}
      >
        <div className='form-container'>
          <Form className=''>
            <br></br>
            <label htmlFor="name">Name:</label>
            <Field name="name" type="text" />
            <br></br>
            <label htmlFor="email">Email</label>
            <Field name="email" type="text" />
            <br></br>
            <label htmlFor="mobile">Mobile</label>
            <Field name="mobile" type="text" />
            <br></br>
            <label htmlFor="password">Password</label>
            <Field name="password" type="password" />
            <br></br>

            <button type="submit" className='btn btn-success'>Add User</button>

            <br></br>
          </Form>
        </div>
      </Formik>
      <ToastContainer />
    </div>
  )
}

export default UserAdd
