import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import loadImage from '../images/giphy.gif';

const MyallClassessandAttaindence = () => {
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const [details, setDetails] = useState();
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const headers = {
                    'x-access-token': localStorage.getItem('jwttoken')
                };
                setLoader(true);
                const userId = location.pathname.split('/').pop();
                const url = process.env.REACT_APP_API_URL + 'classes/ownAttendance';
                const response = await axios.post(url, {
                    userId
                }, { headers });
                const url2 = process.env.REACT_APP_API_URL + 'users/searchuser/' + userId;
                const res = await axios.get(url2, { headers });
                setDetails(res.data);
                setUserData(response.data);
                setLoader(false);


            } catch (error) {
                //console.error('Error fetching user details:', error);
                setLoader(false);
            }
        };

        fetchUserData();
    }, [location]);

    return (

        <div>
            {loader ? (
                <div className=" col-8 loading">
                    <img src={loadImage} alt="Loader" />
                </div>
            ) : (
                <>
                    <div className="container mt-5">

                        <h2 className="mb-4">All Assignment and  Classes</h2>

                        {userData ? (
                            <div className="card">
                                <div className="card-body">


                                    <h3 className="mt-4 mb-3">Classes</h3>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Class Name</th>
                                                <th>Class Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData.classes.map((classInfo, index) => (
                                                <tr key={classInfo.classname} className={index % 2 === 0 ? 'table-light' : ''}>
                                                    <td>{classInfo.classname}</td>
                                                    <td style={{ color: classInfo.class_status === 'true' ? 'green' : 'red' }}>
                                                        {classInfo.class_status}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <h3 className="mt-4 mb-3">Assignments</h3>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Task Name</th>
                                                <th>Marks Obtained</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData.assignments.map((assignment, index) => (
                                                <tr key={assignment.taskid} className={index % 2 === 0 ? 'table-light' : ''}>
                                                    <td>{assignment.taskname}</td>
                                                    <td style={{ color: assignment.marksobtained === 'You have not attempted this task yet' ? 'red' : 'green' }}>
                                                        {assignment.marksobtained}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <p>Loading user details...</p>
                        )}
                    </div>
                </>
            )}
        </div>

    );
};

export default MyallClassessandAttaindence;
