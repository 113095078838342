import React, { useState, useEffect, useRef } from 'react'
import header from '../images/letterhead_top.png';
import footer from '../images/letterhead_bottom.png';
import sign from '../images/sign.png';
import sign2 from '../images/rounak_sign.png';
import mca from '../images/mca.png';
import aicte from '../images/aicte.png';
import new_seal from '../images/seal_new.png';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import { Button } from "react-bootstrap";
import headernew from '../images/letterhead_top_pvtltd.png';
import footernew from '../images/letterhead_bottom_pvtltd.png';
import logo from '../images/logo_full.png';

const ExpLetterDesign = () => {

    const { userid } = useParams();
    console.log('userid - ', userid);

    const [userDetail, setUserDetail] = useState([]);
    const navigate = useNavigate();
    const backButtonRef = useRef(null);

    const fetchUserDetail = async () => {
        try {
            const headers = {
                'x-access-token': localStorage.getItem('jwttoken')
            };
            const response = await axios.get(process.env.REACT_APP_API_URL + 'course/userDetails/' + userid, { headers });
            setUserDetail(response.data);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        fetchUserDetail();
    }, []);

    // const handlePrint = () => {
    //     // Hide the back Button
    //     if (backButtonRef.current) {
    //         backButtonRef.current.style.display = 'none';
    //     }

    //     // Triger the Print dailog
    //     window.print();

    //     // show the back Button again after printing
    //     if (backButtonRef.current) {
    //         backButtonRef.current.style.display = 'flex';
    //     }

    // };

    const root = document.querySelector("#root");
    const bodyRef = useRef(document.body);
    const printContainerRef = useRef(null);

    const printDiv = () => {
        const clone = printContainerRef.current.cloneNode(true);
        bodyRef.current.innerHTML = '';
        bodyRef.current.appendChild(clone);
        window.print();
        bodyRef.current.innerHTML = '';
        bodyRef.current.appendChild(root);
    };

    return (
        <div className='full-screen-print full-screen-print-design text-center'>

            <div ref={backButtonRef} className="on-print button-container">
                <Button onClick={() => navigate(-1)} variant="secondary" >
                    Back
                </Button>
                <Button onClick={printDiv} variant="primary">
                    Print
                </Button>
            </div>

            <div className='print-container print-container-certi-design' ref={printContainerRef}>
                <div className='print-container-content-certi-design'>
                    <div className='row'>
                        <div className='col-2 offset-1'><img src={mca} alt='mca' /></div>
                        <div className='col-4 offset-1'><img src={logo} alt='logo' /></div>
                        <div className='col-2 offset-2'><img src={aicte} alt='aicte' className='logo-3' /></div>
                    </div>

                    <div className='py-4'>

                        <h3 className='certi-header'>Internship Certificate</h3>

                        <div>
                            <div className='row my-5'>
                                <div className='col-4'>Emp. ID – {userDetail.employeeid}</div>
                                <div className='col-4'></div>
                                <div className='col-4'>Certificate No - {userDetail.certificateid}</div>
                            </div>


                            <div>This is to certify that <br></br><strong>{userDetail.gender === 'male' ? 'Mr.' : 'Ms.'} {userDetail.name}</strong><br></br> student / alumni of <br></br> <strong>{userDetail.college}</strong><br></br> has successfully completed {userDetail.gender === 'male' ? 'his' : 'her'} internship in <strong>Full Stack Development in IT Applications Development</strong> domain for <br></br> <strong>{userDetail.duration} from {userDetail.startdate} to {userDetail.enddate}</strong> as Software Developer Intern.
                            </div>


                            <br></br>

                            <div className='row mt-3'>
                                <div className='col-4'>
                                    <img src={sign2} alt='header' style={{ width: '150px' }} />
                                </div>
                                <div className='col-4'>

                                </div>
                                <div className='col-4'>
                                    <img src={sign} alt='header' style={{ width: '150px' }} />

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-4'>
                                    <span className='fw-bold'>Rounak Raghuwanshi
                                        <br></br>
                                        Program Coordinator
                                    </span>
                                </div>
                                <div className='col-4'>

                                </div>
                                <div className='col-4'>
                                    <span className='fw-bold'>Sushma Kumari
                                        <br></br>
                                        HR Manager
                                    </span>
                                </div>
                            </div>


                            <div className='mt-5'>
                                To verify the certificate, visit - <a href='https://www.hashedbit.com/verification' target='_blank'>https://www.hashedbit.com/verification</a>
                            </div>


                        </div>

                    </div>
                    {/* <div className='footer-bottom text-center'>
                        <span>To verify the certificate, visit - <a href='https://www.hashedbit.com/verification' target='_blank'>https://www.hashedbit.com/verification</a><br></br><br></br></span>

                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ExpLetterDesign
