import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from "formik";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from "react-bootstrap";
// const _ = require('lodash');

const notifydata = {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}
const successNotify = () => toast.success("User updated Successfully!", notifydata);
const errNotify = () => toast.error("User not updated!", notifydata);

const UserUpdate = () => {
  const { id } = useParams();
  const userDataIntialState = { userid: id, name: " ", mobile: " " };
  const [updateData, SetUpdateData] = useState(userDataIntialState);
  const [selectedFile, setSelectedFile] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [updateUser, setUpdateUser] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const callUserUpdateApi = async (data) => {
    const url = process.env.REACT_APP_API_URL + 'users/updateuser';
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      await axios.post(url, data, { headers });
      successNotify();
      setTimeout(() => {
        navigate('/userslist');
      }, 1200);
    } catch (err) {
      errNotify();
    }
  }

  const getUserbyIdApi = async (id) => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const url = process.env.REACT_APP_API_URL + 'users/searchuser/' + id;
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    (async () => {
      let data = await getUserbyIdApi(id);
      SetUpdateData(data[0]);
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      // Fetch the profile image
      const imageResponse = await axios.get(`${process.env.REACT_APP_API_URL}course/userDetails/${id}`, { headers });
      if (imageResponse.data.profile_image) {
        const fullImageUrl = `${process.env.REACT_APP_API_URL}${imageResponse.data.profile_image.replace(/\\/g, '/')}`;
        setImageUrl(fullImageUrl);
      } else {
        setImageUrl('');
      }
    })();
  }, [id]);

  const handleClose = () => {
    setShow(false);
    errNotify();
  };
  const handleClickUpdate = (values) => {
    setUpdateUser(values);
    setShow(true);
  };
  const handleUpdateItem = () => {
    callUserUpdateApi(updateUser);
    setShow(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}course/userDetails/uploads/profile/${id}`,
        formData,
        {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'x-access-token': localStorage.getItem('jwttoken')
          }
        }
      );
      console.log('File upload response:', response.data);
      alert('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file');
    }
  };

  return (
    <div>
      <h4 className=''>Update User Details</h4>
      <Formik
        enableReinitialize={true}
        initialValues={updateData}
        onSubmit={async (values) => {
          handleClickUpdate(values)
        }}
      >
        <div className='form-container'>
          <Form >
            <Field hidden type="number" name="userid"></Field>
            <label htmlFor="name">Name :</label>
            <Field name="name" type="text" max="100" />
            <br></br>
            <label htmlFor="mobile" >Mobile :</label>
            <Field name="mobile" type="text" />
            <br></br>
            <button type="submit" className='btn btn-warning'>Update User</button>
          </Form>
          <div>
            <h2>File Upload Form</h2>
            <input type="file" name="file" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload File</button>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <h2>User Profile Photo</h2>
              {imageUrl ? (
                <img src={imageUrl} alt="UserImage" className="img-fluid rounded-circle border border-dark" style={{ width: '150px', height: '150px' }} />
              ) : (
                <p>No image available</p>
              )}
            </div>
          </div>
        </div>
      </Formik>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>Confirm Update?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sure you want to Update User ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdateItem}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  )
}
export default UserUpdate;
