import React, { useState, useEffect, useRef } from 'react'
import header from '../images/letterhead_top.png';
import footer from '../images/letterhead_bottom.png';
import sign from '../images/sign.png';
import sign2 from '../images/rounak_sign.png';
import mca from '../images/mca.png';
import aicte from '../images/aicte.png';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import { Button } from "react-bootstrap";
import new_seal from '../images/seal_new.png';
import headernew from '../images/letterhead_top_pvtltd.png';
import footernew from '../images/letterhead_bottom_pvtltd.png';

const OfferLetter = () => {

    const { userid } = useParams();
    //console.log('userid - ', userid);

    const [userDetail, setUserDetail] = useState([]);
    const navigate = useNavigate();
    const backButtonRef = useRef(null);

    const fetchUserDetail = async () => {
        try {
            const headers = {
                'x-access-token': localStorage.getItem('jwttoken')
            };
            const response = await axios.get(process.env.REACT_APP_API_URL + 'course/userDetails/' + userid, { headers });
            setUserDetail(response.data);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        fetchUserDetail();
    }, []);

    // const handlePrint = () => {
    //     // Hide the back button
    //     if (backButtonRef.current) {
    //         backButtonRef.current.style.display = 'none';
    //     }

    //     // Trigger the print dialog
    //     window.print();

    //     // Show the back button again after printing
    //     if (backButtonRef.current) {
    //         backButtonRef.current.style.display = 'flex';
    //     }
    // };

    const root=document.querySelector("#root");
    const bodyRef = useRef(document.body);
    const printContainerRef = useRef(null);
  
    const printDiv = () => {
      const clone = printContainerRef.current.cloneNode(true);
      bodyRef.current.innerHTML = '';
      bodyRef.current.appendChild(clone); 
      window.print();
      bodyRef.current.innerHTML = '';
      bodyRef.current.appendChild(root);     
    };
    return (
        <div className='full-screen-print'>

            <div ref={backButtonRef} className="no-print button-container" >
                <Button onClick={() => navigate(-1)} variant="secondary">
                    Back
                </Button>
                <Button onClick={printDiv} variant="primary">
                    Print
                </Button>
            </div>

            <div className='print-container' ref={printContainerRef}>
            <div><img src={headernew} alt='header' /></div>

            <div className='py-5 px-5'>
                
                <h2 className='certi-header'>HashedBit Innovations - Internship Offer Letter</h2>
                


                <div>
                    <br></br>
                    {/* Date: 18th March 2024
                    <br></br><br></br> */}


                    <span className='fw-bold'>
                        Name - {userDetail.name}<br></br>
                        Address - {userDetail.city}<br></br>
                        College – {userDetail.college}<br></br>
                        Email - {userDetail.email}<br></br>
                        <br></br>
                    </span>

                    <span className='fw-bold'>Dear {userDetail.name},</span>
                    <br></br><br></br>

                    Thank you for exploring career opportunities with HashedBit Innovations. You have successfully completed our initial selection process and we are pleased to offer internship in our organization. Details of your internship are as under.
                    <br></br><br></br>

                    <span className='fw-bold'>Designation – Software Developer Intern
                        <br></br>
                        Joining Date – {userDetail.startdate}
                        <br></br>
                        Duration – {userDetail.duration}
                        <br></br>
                        Location – Remote/WFH
                    </span>
                    <br></br><br></br>

                    Your internship will be bound by the terms and conditions of the internship. Please, send your acceptance of the offer letter by mail along with the documents asked.
                    
                    Looking forward to have a great professional collaboration.

                    <br></br><br></br>
                    For HashedBit Innovations Pvt Ltd
                    <br></br>


                    <div className='row'>
                        <div className='col-4'>
                            <img src={sign2} alt='header' style={{ width: '150px' }} />
                        </div>
                        <div className='col-4'>

                        </div>
                        <div className='col-4'>
                            <img src={sign} alt='header' style={{ width: '150px' }} />

                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'>
                            <span className='fw-bold'>Program Coordinator
                                <br></br>
                                HashedBit Innovations Pvt Ltd
                            </span>
                        </div>
                        <div className='col-4'>

                        </div>
                        <div className='col-4'>
                            <span className='fw-bold'>HR Manager
                                <br></br>
                                HashedBit Innovations Pvt Ltd
                            </span>
                        </div>
                    </div>


                    <div className='row pt-2'>
                        <div className='col-4'>
                            <img src={new_seal} alt='header' style={{ width: '100px' }} />
                        </div>
                        <div className='col-4 py-4 text-center'>
                            <img src={mca} alt='header' style={{ width: '130px' }} />
                        </div>
                        <div className='col-4 text-end'>
                            <img src={aicte} alt='header' style={{ width: '100px' }} />
                        </div>
                    </div>

                    {/* <img src={sign} alt='header' style={{ width: '150px' }} />
                    <img src={new_seal} alt='header' style={{ width: '130px' }} className='seal' />
                    <br></br>
                    <span className='fw-bold'>HR Manager
                        <br></br>
                        HashedBit Innovations Pvt Ltd
                    </span>
                    <br></br> */}
                </div>

            </div>
            <div className='footer-bottom'><img src={footernew} alt='header' /></div>
        </div>
        </div>
    )
}

export default OfferLetter;
