import React, { useState, useEffect } from 'react';
import axios from 'axios';
import loadImage from "../images/giphy.gif";

const AttendanceMern = () => {

  const [attendanceList, setAttendanceList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cols, setCols] = useState([]);

  const callApiAttendanceList = async () => {
    try {
      setLoader(true);
      // const url = process.env.REACT_APP_API_URL + 'attendance/attendanceListAll';
      const url = process.env.REACT_APP_API_URL + 'attendance/attendanceListAll';
      //console.log(url);
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      //console.log(response.data[0].classid);
      // console.log(response.data)
      setAttendanceList(response.data);
      setLoader(false);


      let obj = response.data[0]
      delete obj.userid;
      delete obj.name;
      delete obj.total;
      setCols(obj);
      // console.log(cols);
    }
    catch (error) {
      // console.log(error);\
      setLoader(false);
    }
  }


  useEffect(() => {
    callApiAttendanceList();
  }, [])

  return (

    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>

          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User ID</th>
                <th>Name</th>
                {Object.keys(cols).map((key1) => {

                  let str = key1.split('_').slice(1).join(' ')

                  return (
                    <th>{str}</th>
                  )

                  // console.log(key1)
                })}
                <th>Total</th>
              </tr>

            </thead>
            <tbody>
              {
                attendanceList && attendanceList.map((item, index) => {
                  let tempObj = Object.assign({}, item);;
                  delete tempObj.userid;
                  delete tempObj.name;
                  delete tempObj.total;
                  let temp = Object.keys(tempObj);
                  //console.log(temp);


                  return (

                    <tr key={index + item.userid}>
                      <td>{index + 1}</td>
                      <td>{item.userid}</td>
                      <td>{item.name}</td>

                      {
                        temp.map((item2, index2) => (
                          <td className={parseInt(item[item2]) ? 'bg-success-subtle' : 'bg-danger-subtle'}>{item[item2]}</td>
                        ))
                      }
                      <td>{item.total}</td>


                      {/* <td>
                    <Link to={`/classedit/${item.classid}`} className='btn btn-warning'>Edit</Link>
                  </td>
                <td>
                  <button onClick={() => handleClickDelete(item.classid)} className='btn btn-danger'>Delete</button>
                </td> */}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

export default AttendanceMern
