import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataAppContext } from '../DataContext';

const LeftPanel = (props) => {
    const localContext = useContext(DataAppContext);
    const { appstate, logout_user } = localContext;
    const { loginstatus } = appstate;
    const usertype = localStorage.getItem('usertype');
    const { isMobileMenu, setMobileMenu } = props;
    // console.log('localcontext in left panel - ', localContext);

    const [isloginpage, setLoginPage] = useState(false);

    const [leftPanelClasses, setLeftPanelClasses] = useState('left-panel');

    useEffect(() => {
        //console.log('---------checking login page')
        if(window.location.pathname === '/login') {
            setLoginPage(true)
            //console.log('---------checking login page true')
        }
        else {
            setLoginPage(false)
            //console.log('---------checking login page false')
        }
    }, [window.location.pathname])

    useEffect(() => {
        //console.log('isloginpage, isMobileMenu', isloginpage, isMobileMenu )
        if(isloginpage || isMobileMenu) {
            //console.log('menu off' )
            setLeftPanelClasses('left-panel d-none');
        }
        else {
            //console.log('menu on' )
            setLeftPanelClasses('left-panel');
        }
    }, [isloginpage, isMobileMenu])

    return (

        <>
            <aside id="left-panel" className={leftPanelClasses}>
                <nav class="navbar navbar-expand-sm navbar-default">
                    <div id="main-menu" class="main-menu collapse navbar-collapse">


                        <ul class="nav navbar-nav">

                            {/* <li class="active">
                                <a href="index.html"><i class="menu-icon fa fa-laptop"></i>Dashboard </a>
                            </li> */}
                            {/* <li class="menu-title">User Options</li> */}
                            <li class="menu-title">&nbsp;</li>

                            {
                                (localContext.appstate.loginstatus && (usertype === 'admin')) && (
                                    <>
                                        <li class="menu-item-has-children"><b>Admin Options</b></li>
                                        <li class="menu-item-has-children dropdown bg-warning bg-opacity-25"><Link to='/userslist' >Users List</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/mernuserslist' >MERN Users List</Link></li>
                                        <li class="menu-item-has-children dropdown bg-danger bg-opacity-10"><Link to='/mernregister' >XXX - MERN Register</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/classlist' >Class List</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/AllClasses' >Classes</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/attendancemern' >Attendance MERN</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/attendancelist' >Attendance List</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/syllabuslist' >Syllabus List</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/assignmentslist' >Assignment List</Link></li>
                                        <li class="menu-item-has-children dropdown bg-danger bg-opacity-10"><Link to='/updateregisteredusers' >XXX - Edit 3 Col - Reg Users</Link></li>
                                        <li class="menu-item-has-children dropdown bg-warning bg-opacity-25"><Link to='/users_registered' >Registered Users List</Link></li>
                                        <li class="menu-item-has-children dropdown bg-warning bg-opacity-25"><Link to='/uniqueregisteredusers' >Unique Filtered Users</Link></li>
                                        <li class="menu-item-has-children dropdown bg-success bg-opacity-25"><Link to='/internslist' >Tech Interns List</Link></li>
                                        <li class="menu-item-has-children dropdown bg-success bg-opacity-25"><Link to='/mbainternslist' >MBA Interns List</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/marksextra' >Assign Extra Marks</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/settinglist' className=''>Settings</Link></li>
                                        <li class="menu-item-has-children"><b>Student Options</b></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/dashboard' className=''>Dashboard</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/allclasses' >Classes</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/batchclass' >Batch Classes</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/assignmentall' >Assignment</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/assignment' >Batch Assignment</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/quiz' >Quiz</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/leaderboard' >Leaderboard</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/myattendance' >My Attendance</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/allStatus' >My Status</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/userprofile' >My Profile</Link></li>


                                        <li class="menu-item-has-children"><b>Mock Options</b></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/mocks' >Mocks List</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/dashboard' className=''>Mock Dashboard</Link></li>
                                    </>
                                )
                            }

                            {
                                (localContext.appstate.loginstatus && (usertype === 'student')) && (
                                    <>
                                        {/* <Link to='' className='btn btn-success'>Students Options</Link><br></br><br></br> */}
                                        <li class="menu-item-has-children dropdown"><Link to='/dashboard' className=''>Dashboard</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/allclasses'>Classes</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/batchclass'>Batch Classes</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/assignmentall'>Assignment</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/assignment'>Batch Assignment</Link></li>
                                        {/* <li class="menu-item-has-children dropdown"><Link to='/quiz' >Quiz</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/leaderboard' >Leaderboard</Link></li> */}
                                        {/* <li class="menu-item-has-children dropdown"><Link to='/myStatus' >My Status</Link></li> */}
                                        <li class="menu-item-has-children dropdown"><Link to='/myattendance' >My Attendance</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/userprofile' >My Profile</Link></li>
                                    </>
                                )
                            }

                            {
                                (localContext.appstate.loginstatus && (usertype === 'mock')) && (
                                    <>
                                        {/* <Link to='' className='btn btn-success'>Students Options</Link><br></br><br></br> */}
                                        <li class="menu-item-has-children dropdown"><Link to='/dashboard' className=''>Mock Dashboard</Link></li>
                                        <li class="menu-item-has-children dropdown"><Link to='/mocks' >Mocks List</Link></li>
                                    </>
                                )
                            }


                            <li class="menu-item-has-children dropdown">
                                {loginstatus && (
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={logout_user}
                                    >
                                        Logout
                                    </button>
                                )}
                                {!loginstatus && window.location.pathname !== "/" && (
                                    <Link className="btn btn-outline-success" to="/login">
                                        Login
                                    </Link>
                                )}
                            </li>

                            {/* <li class="menu-item-has-children dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon fa fa-cogs"></i>Components</a>
                                
                            </li>
                            <li class="menu-item-has-children dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon fa fa-table"></i>Tables</a>
                                <ul class="sub-menu children dropdown-menu">
                                    <li><i class="fa fa-table"></i><a href="tables-basic.html">Basic Table</a></li>
                                    <li><i class="fa fa-table"></i><a href="tables-data.html">Data Table</a></li>
                                </ul>
                            </li>
                            <li class="menu-item-has-children dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon fa fa-th"></i>Forms</a>
                                <ul class="sub-menu children dropdown-menu">
                                    <li><i class="menu-icon fa fa-th"></i><a href="forms-basic.html">Basic Form</a></li>
                                    <li><i class="menu-icon fa fa-th"></i><a href="forms-advanced.html">Advanced Form</a></li>
                                </ul>
                            </li> */}

                            {/* <li class="menu-title">Icons</li>

                            <li class="menu-item-has-children dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon fa fa-tasks"></i>Icons</a>
                                <ul class="sub-menu children dropdown-menu">
                                    <li><i class="menu-icon fa fa-fort-awesome"></i><a href="font-fontawesome.html">Font Awesome</a></li>
                                    <li><i class="menu-icon ti-themify-logo"></i><a href="font-themify.html">Themefy Icons</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="widgets.html"> <i class="menu-icon ti-email"></i>Widgets </a>
                            </li>
                            <li class="menu-item-has-children dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon fa fa-bar-chart"></i>Charts</a>
                                <ul class="sub-menu children dropdown-menu">
                                    <li><i class="menu-icon fa fa-line-chart"></i><a href="charts-chartjs.html">Chart JS</a></li>
                                    <li><i class="menu-icon fa fa-area-chart"></i><a href="charts-flot.html">Flot Chart</a></li>
                                    <li><i class="menu-icon fa fa-pie-chart"></i><a href="charts-peity.html">Peity Chart</a></li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </nav>

            </aside>
        </>
    )
}

export default LeftPanel
