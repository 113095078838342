import React, { useState } from "react";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadImage from "../images/giphy.gif";

const MarksExtra = () => {
  const formValues = {
    marks: "",
    userid: "",
  };
  const notifydata = {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  const successNotify = () => toast.success("Extra Marks Added", notifydata);

  const errNotify = () => toast.error("Extra marks not added", notifydata);

  const callMarksExtraAPI = async (values) => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const url = process.env.REACT_APP_API_URL + "leaderboard/marksextra";
      const response = await axios.post(url, values, { headers });
      successNotify();

      // setLoader(false);




    } catch (error) {
      errNotify();
      // setLoader(false);
    }
  };

  return (
    <div>



      <h4 className="ClassAdd-heading">Update Class</h4>
      <Formik
        initialValues={formValues}
        onSubmit={async (values, { resetForm }) => {
          callMarksExtraAPI(values);
          resetForm();
        }}
      >
        <div className="form-container">
          <Form className="">
            <div>
              <label>User Id</label>
              <Field name="userid" type="text" />
            </div>
            <div>
              <label>Extra Marks</label>
              <Field name="marks" type="number" />
            </div>
            <div>
              <button type="submit" className="btn btn-success">
                Add Extra Marks
              </button>
            </div>
          </Form>
        </div>
      </Formik>
      <ToastContainer />

    </div>

  );
};

export default MarksExtra;