import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JoditEditor from 'jodit-react';

const _ = require('lodash');
const notifydata = {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}
const successNotify = () => toast.success("Assignment Added Successfully!", notifydata);
const errNotify = () => toast.error("Assignment not Added!", notifydata);
const AssignmentAdd = () => {
  const handleEditorBlur = (content) => {
    setTaskContent(content);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [taskname, setTaskname] = useState('');
  const [taskContent, setTaskContent] = useState('');
  const [totalMarks, setTotalMarks] = useState('');
  const [classid, setClassid] = useState('');
  const [startdate, setStartdate] = useState(getCurrentDate());
  const [enddate, setEnddate] = useState('2024-12-31');
  const [classList, setClassList] = useState([]);
  const navigate = useNavigate();


  const callApiClassList = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const url = process.env.REACT_APP_API_URL + 'classes/classeslist';
      //console.log(url);
      const response = await axios.get(url, { headers });
      setClassList(response.data);
    }
    catch (error) {
      // console.log(error);
    }
  }
  const editor = useRef(null);

  useEffect(() => {
    callApiClassList();
  }, [])

  const editorConfig = {
    height: 300,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const editorContent = editor.current.value;

    // console.log("Editor Content:", editorContent);
    const url = process.env.REACT_APP_API_URL + 'assignments/add';
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.post(url, {
        taskname,
        taskcontent: editor.current.value,
        classid,
        total_marks: parseInt(totalMarks, 10),
        startdate,
        enddate,
      }, { headers });
      successNotify();
      setTimeout(() => {
        navigate('/assignmentslist');
      }, 1200);
      setTaskname('');
      setTaskContent('');
      setClassid('');
      setTotalMarks('');
    } catch (error) {
      errNotify();
    }
  };

  return (
    <>
      <div>
        <h1>Add Assignment</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Task Name : </label>
            <input required type="text" value={taskname} onChange={(e) => setTaskname(e.target.value)} />
          </div>
          <div>
            <label>Task Content :</label>
            <JoditEditor
              ref={editor}
              value={taskContent}
              config={editorConfig}
              onBlur={(content) => handleEditorBlur(content)}
            />
          </div>
          <div>
            <label>Class ID :</label>
            <select required value={classid} onChange={(e) => setClassid(e.target.value)}>
              <option value="">Select a Class</option>
              {classList && classList.map((item) => (
                <option key={item.classid} value={item.classid}>
                  {item.classid} - {item.classname}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Total Marks :</label>
            <input
              required
              type="text"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />
          </div>
          <div>
            <label>Start Date :</label>
            <input required type="date"
              value={startdate}
              onChange={(e) => setStartdate(e.target.value)}
            />
          </div>
          <div>
            <label>End Date : </label>
            <input required type="date"
              value={enddate}
              onChange={(e) => setEnddate(e.target.value)}
            />
          </div>
          <button type="submit" className='btn btn-success'>Add New Assignment</button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};
export default AssignmentAdd;


