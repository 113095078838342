import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssignmentAnswerList = () => {

    const [assignmentAnswerList, setAssignmentAnswerList] = useState([]);

    const [check, setCheck] = useState(false);
    const [selectedUsername, setSelectedUsername] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [selectedAssignment, setSelectedAssignment] = useState('');
    const [filteredAssignmentAnswerList, setFilteredAssignmentAnswerList] = useState([]);
    const [selectedTaskAnswer, setSelectedTaskAnswer] = useState('');

    const notifydata = {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const successNotify = () => toast.success("Assignment Approved!", notifydata);
    const errNotify = () => toast.error("Assignment Not Approved!", notifydata);

    const userDataInitialState = {
        taskid: '',
        userid: '',
        marksobtained: '',
        verifiedstatus: 1,
    };
    const callApiAssignmentAnswerList = async () => {
        try {
            let values = {};
            if (check === true) {
                values.verifiedstatus = 0;
            } else {
                values.verifiedstatus = 1;
            }

            const url = process.env.REACT_APP_API_URL + 'assignments/showalltaskanswers';
            const headers = {
                'x-access-token': localStorage.getItem('jwttoken')
            };
            const response = await axios.post(url, values, { headers });
            setAssignmentAnswerList(response.data);
            setCheck(prev => !prev);
        } catch (err) {
            // console.log(err);
        }
    }

    const [selectedTasks, setSelectedTasks] = useState(userDataInitialState);
    const callApiTaskList = async (task_id, srno) => {
        try {
            const headers = {
                'x-access-token': localStorage.getItem('jwttoken')
            };

            const url = process.env.REACT_APP_API_URL + 'taskanswers/search';
            const response = await axios.post(url, {
                taskid: task_id,
                srno: srno
            }, { headers })
            setSelectedTasks(response.data)
        } catch (err) {
            // console.log(err);
        }
    }
    const handleCheckboxChange = (e) => {
        e.preventDefault();
        callApiAssignmentAnswerList();
    };


    const handleUsernameFilterChange = (e) => {
        const selectedUsername = e.target.value;
        setSelectedUsername(selectedUsername);
        setSelectedName('')
    };
    const handleNameFilterChange = (e) => {
        const selectedName = e.target.value;
        setSelectedName(selectedName);
        setSelectedUsername('')
    };

    const handleAssignmentFilterChange = (e) => {
        const selectedAssignment = e.target.value;
        setSelectedAssignment(selectedAssignment);
    };

    useEffect(() => {
        const filteredList = assignmentAnswerList.filter(item => {
            const userFilter = selectedUsername === '' || item.username === selectedUsername;
            const nameFilter = selectedName === '' || item.name === selectedName;
            const assignmentFilter = selectedAssignment === '' || item.taskname === selectedAssignment;

            return userFilter && nameFilter && assignmentFilter;
        });

        setFilteredAssignmentAnswerList(filteredList);
    }, [assignmentAnswerList, selectedUsername, selectedName, selectedAssignment]);


    useEffect(() => {
        callApiAssignmentAnswerList();
    }, []);

    const getUsernameOptions = () => {

        const uniqueUsernames = new Set();
        assignmentAnswerList.forEach((user) => {
            uniqueUsernames.add(user.username);
        });
        const uniqueUsernamesArray = Array.from(uniqueUsernames);

        if (uniqueUsernamesArray.length === 0) {
            return (
                <option value="">Loading Users...</option>
            );
        } else {
            return uniqueUsernamesArray.map((username) => (
                <option key={username} value={username} style={{ fontWeight: selectedUsername === username ? 'bold' : 'normal', }}>
                    {username}
                </option>
            ));
        }
    }
    const getNameOptions = () => {

        const uniqueNames = new Set();
        assignmentAnswerList.forEach((user) => {
            uniqueNames.add(user.name);
        });
        const uniqueNamesArray = Array.from(uniqueNames);

        if (uniqueNamesArray.length === 0) {
            return (
                <option value="">Loading Users...</option>
            );
        } else {
            return uniqueNamesArray.map((uname) => (
                <option key={uname} value={uname} style={{ fontWeight: selectedName === uname ? 'bold' : 'normal', }}>
                    {uname}
                </option>
            ));
        }
    }
    const getAssignmentOptions = () => {

        const uniqueTaskNames = new Set();

        assignmentAnswerList.forEach((assignment) => {
            uniqueTaskNames.add(assignment.taskname);
        });

        const uniqueTaskNamesArray = Array.from(uniqueTaskNames);

        if (uniqueTaskNamesArray.length === 0) {
            return (
                <option value="">Loading Assignments...</option>
            );
        } else {
            return uniqueTaskNamesArray.map((taskname) => (
                <option key={taskname} value={taskname} style={{ fontWeight: selectedAssignment === taskname ? 'bold' : 'normal' }}>
                    {taskname}
                </option>
            ));
        }
    }

    const clearFilters = () => {
        setSelectedUsername('');
        setSelectedName('');
        setSelectedAssignment('');
    }

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const showAnswerModal = (taskanswer, task_id, srno) => {
        setSelectedTaskAnswer(taskanswer);
        callApiTaskList(task_id, srno);
        setShow(true);
    };

    const handleSubmit = async (values) => {
        try {
            const headers = {
                'x-access-token': localStorage.getItem('jwttoken')
            };
            const url = process.env.REACT_APP_API_URL + 'leaderboard/marksforTaskSubmission';
            const response = await axios.post(url, values, { headers });
            if (response.status === 200) {
                successNotify();
            }
            else {
                errNotify();
            }
        } catch (err) {
            // console.error(err);
        }
        setShow(false);
    };
    return (
        <div>
            <h1>All Assignment Answers</h1>
            <div>
                <label>
                    Show Verified Answers:
                    <input
                        type="checkbox"
                        checked={check}
                        onChange={handleCheckboxChange}
                    />
                </label>
                <button onClick={clearFilters} className="btn btn-primary" style={{ marginLeft: '50px' }} >Clear Filters</button>

            </div>
            <table className='table table-striped' >
                <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>
                            <select value={selectedUsername} onChange={handleUsernameFilterChange} >
                                <option value="" style={{ fontWeight: 'bold' }}>User Name</option>
                                {getUsernameOptions()}
                            </select>
                        </th>
                        <th>
                            <select value={selectedName} onChange={handleNameFilterChange} >
                                <option value="" style={{ fontWeight: 'bold' }}>Name</option>
                                {getNameOptions()}
                            </select>
                        </th>
                        <th>
                            <select value={selectedAssignment} onChange={handleAssignmentFilterChange}  >
                                <option value="" style={{ fontWeight: 'bold' }}>Assignment Name</option>
                                {getAssignmentOptions()}
                            </select>
                        </th>
                        <th>Total Marks</th>
                        <th>Marks Obatained</th>
                        <th>Submit Date</th>
                        <th>Verified Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAssignmentAnswerList && filteredAssignmentAnswerList.map((item, index) => (
                        <tr key={index + item.taskid}>
                            <td>{index + 1}</td>
                            <td>{item.username}</td>
                            <td>{item.name}</td>
                            <td>{item.taskname}</td>
                            <td>{item.total_marks}</td>
                            <td>{item.marksobtained}</td>
                            <td>{item.submittedondate}</td>
                            <td>{item.verifiedstatus}</td>
                            <td>
                                <button onClick={() => showAnswerModal(item.taskanswer, item.taskid, item.srno)} className='btn btn-primary'>View Answer</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Answer</Modal.Title>
                </Modal.Header>
                <Modal.Body><div dangerouslySetInnerHTML={{ __html: selectedTaskAnswer }} />
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ ...selectedTasks, verifiedstatus: 1 }}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                <div>
                                    <Field hidden type="number" name="taskid" />
                                </div>
                                <div>
                                    <Field hidden type="text" name="userid" />
                                </div>
                                <div>
                                    <label>Marks:</label>
                                    <Field type="number" name="marksobtained" />
                                </div>
                                <div>
                                    <Field hidden type="number" name="verifiedstatus" />
                                </div>
                                <Button type="submit" variant="primary" style={{ marginLeft: '10px' }}>Approve</Button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    );
}

export default AssignmentAnswerList;
