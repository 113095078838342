import React, { useState, useEffect } from 'react';
import SecureHeader from '../shared/SecureHeader';
import SecureLeftPanel from '../shared/SecureLeftPanel';
import SecureFooter from '../shared/SecureFooter';
import axios from 'axios';
import { Link } from 'react-router-dom';

const DashboardAdmin = () => {

  // const [loginStatus, setLoginStatus] = useState(false);

  // const checkLogin = () => {
  //   let token = localStorage.getItem('jwttoken');
  //   if (token) {
  //     setLoginStatus(true);
  //   }
  //   else {
  //     setLoginStatus(false);
  //   }
  // }

  // useEffect(() => {
  //   checkLogin();
  // })

  // useEffect(() => {
  //   console.log('login status - ', loginStatus )
  // })

  return (
    <>
      <div>
        Dashboard
      </div>
    </>
  )
}

export default DashboardAdmin;
