import React from 'react'

const Quiz = () => {
  return (
    <div>

    </div>
  )
}

export default Quiz
