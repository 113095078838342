import React, { useState, useEffect } from "react";
import axios from "axios";
import loadImage from "../images/giphy.gif";

const Leaderboard = () => {
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(true);

  const callLeaderBoard = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      setLoader(true);
      const url = process.env.REACT_APP_API_URL + 'leaderboard/showall';
      const response = await axios.get(url, { headers });
      //console.log(response.data);
      setUserList(response.data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    callLeaderBoard();
  }, []);

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Userid</th>
                <th>Name</th>
                <th>Marks</th>
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.map((item, index) => (
                  <tr key={index + item.exam_id}>
                    <td>{index + 1}</td>
                    <td>{item.userid}</td>
                    <td>{item.name}</td>
                    <td>{item.marks}</td>

                    {/* <td>
                  <Link to={`/useredit/${item.userid}`} className='btn btn-warning'>Edit</Link>
                </td>
                <td>
                  <button onClick={() => deleteUser(item.userid)} className='btn btn-primary'>Delete User</button>
                </td>
                <td>
                  <button onClick={() => activateUser(item.userid)} className='btn btn-success'>Activate User</button>
                </td> */}
                  </tr>
                ))}
            </tbody>
          </table>

          {/* <div class="alert alert-success" role="alert">
        <b>Benefits :-</b>&nbsp;Top 5 will get paid internship @ HashedBit Innovations
      </div> */}

          <p>
            Note - If your attendance is missed, please connect with your
            mentor.
          </p>
          <p>Note - Assignment marks to be added soon.</p>
          <p>Note - Current ranking is based on class attendance.</p>
        </>
      )}
    </div>
  );
};

export default Leaderboard;
