import React, { useEffect, useState } from 'react';
import Login from './Login';
import MernRegister from '../student/MernRegister';

const Home = () => {
    const [login, setLogin] = useState(false);

    return (
        <>
            {
                login ? (
                    <Login setfn={setLogin}/>
                ) : (
                    <>
                        <div className='row'>
                            <div className='col-12 bg-success-subtle text-center'>
                                <button className='btn btn-primary' onClick={() => setLogin(true)}>Proceed to Login</button>
                            </div>
                        </div>
                        {/* <MernRegister /> */}
                    </>
                )
            }
        </>
    )
}

export default Home
