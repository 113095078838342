
import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Modal, Button } from "react-bootstrap";
import loadImage from "../images/giphy.gif";

const notifydata = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}
const notify = () => toast.success("Class deleted Successfully!", notifydata);
const notifyErr = () => toast.error("Class not deleted!", notifydata);


const ClassList = () => {
  const [classList, setClassList] = useState([]);
  const [loader, setLoader] = useState(false);

  const callApiClassList = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      setLoader(true);
      const url = process.env.REACT_APP_API_URL + 'classes/classeslist';
      //console.log(url);
      const response = await axios.get(url,{headers});
      //console.log(response.data[0].classid);
      //console.log(response.data);
      setClassList(response.data);
      setLoader(false);
     
      
    }
    catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  useEffect(() => {
    callApiClassList();
  }, [])


  const deleteClass = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    //var val = confirm("Sure you want to delete class?");
    //console.log(id);
    //console.log(val);
    //if (val === true) {
      setLoader(true);
      const url = process.env.REACT_APP_API_URL +'classes/removeclass/'+ id;
      //console.log(url);
      try {
        const headers = {
          'x-access-token': localStorage.getItem('jwttoken')
        };
        const response = await axios.put(url,{headers});
        //console.log(response.data);
        setLoader(false);
        notify();
      } catch (error) {
        console.log(error)
      }
    //} else {
    //  alert("Class not Deleted");
   // }
    callApiClassList();
  }
  
  const [deleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    notifyErr();
  };
  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  const handleDeleteItem = () => {
    deleteClass(deleteId);
    setShow(false);
  };

  return (

    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
    
    <>

      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Class Name</th>
            <th>Course Id</th>
            <th>Course Name</th>
            <th>Start Date</th>
            <th>Start Time</th>
            <th>End Date</th>
            <th>Class Link</th>
            <th>Video Link</th>
            <th>Quiz Id</th>
            <th>Topic Id</th>          
          </tr>
        </thead>
        <tbody>
          {
            classList && classList.map((item, index) => (

              <tr key={index + item.classid}>
                <td>{index + 1}</td>
                <td>{item.classname}</td>
                <td>{item.courseid}</td>
                <td>{item.coursename}</td>
                <td>{item?.startdate?.split('T')[0]}</td>
                <td>{item.starttime}</td>
                <td>{item?.enddate?.split('T')[0]}</td>
                <td>{item.classlink}</td>
                <td>{item.videolink}</td>
                <td>{item.quizid}</td>
                <td>{item.topicid}</td>

                
                
                <td>
                    <Link to={`/classedit/${item.classid}`} className='btn btn-warning'>Edit</Link>
                  </td>
                <td>
                  <button onClick={() => handleClickDelete(item.classid)} className='btn btn-danger'>Delete</button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sure you want to delete Class ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDeleteItem}>
            OK
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <br></br>
      <Link to="/classadd" className='btn btn-primary'>Create New Class</Link>
    </>
      )}
    </div>
  )
}

export default ClassList
