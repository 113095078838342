import React, { useState, useEffect } from 'react';
import axios from 'axios';
import loadImage from "../images/giphy.gif";
import Button from 'react-bootstrap/Button';

const AttendanceList = () => {
  const [attendanceList, setAttendanceList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [attendanceData, setAttendanceData] = useState({});

  // Fetch the attendance list on component mount
  useEffect(() => {
    callApiAttendanceList();
  }, []);

  // Fetch attendance remarks whenever selectedDate changes
  useEffect(() => {
    fetchAttendanceRemarks();
  }, [selectedDate]);

  // Fetch remarks for the selected date
  const fetchAttendanceRemarks = async () => {
    console.log(`Fetching attendance remarks for date: ${selectedDate}`); // Debugging
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}attendance/attendanceRemark`, {
        params: { date: selectedDate },
      });
      console.log('Fetched remarks data:', response.data); // Debugging
      const remarksData = response.data.reduce((acc, item) => {
        acc[item.userid] = item.remarks;
        return acc;
      }, {});
      setAttendanceData(remarksData);
    } catch (error) {
      console.error('Error fetching attendance remarks:', error);
    }
  };

  // Fetch all users
  const callApiAttendanceList = async () => {
    try {
      setLoader(true);
      const url = process.env.REACT_APP_API_URL + "users/alluserss";
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      setAttendanceList(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error('Error fetching attendance list:', error);
    }
  };

  // Handle search query change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle date change
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // Update attendance status
  const handleAttendanceUpdate = async (userId, status) => {
    try {
      const url = process.env.REACT_APP_API_URL + 'attendance/attendanceMark';
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const data = {
        userId: userId,
        date: selectedDate,
        status: status
      };
      await axios.post(url, data, { headers });
      alert('Attendance updated successfully');
      fetchAttendanceRemarks(); // Refresh remarks after update
    } catch (error) {
      alert('Failed to update attendance');
    }
  };

  // Filter the attendance list based on search query
  const filteredList = attendanceList.filter((entry) =>
    entry.name && entry.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      {loader ? (
        <div className="col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <div className="container m-2">
            <form className="d-flex">
              <div className="col-1 col-md-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 col-md-4 mt-2 mt-md-0">
                <button type="submit" className="btn btn-primary w-200">Search</button>
              </div>
            </form>
          </div>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Email ID</th>
                <th>Mobile</th>
                <th>Attendance</th>
                <th>Date
                  <input
                    id="dateInput"
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody id="studentList">
              {filteredList.map((item, index) => (
                <tr key={index + item.userid}>
                  <td>{index + 1}</td>
                  <td>{item.userid}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleAttendanceUpdate(item.userid, 'Present')}
                    >
                      Present
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleAttendanceUpdate(item.userid, 'Absent')}
                    >
                      Absent
                    </Button>
                  </td>
                  <td>{selectedDate}</td>
                  <td>{attendanceData[item.userid] || 'No remark'}</td> {/* Display the remark for the user */}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AttendanceList;
