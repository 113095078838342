import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SyllabusAdd = () => {
  const formValues = {
    syllabusname: '',
    courseid: '', // Initialize with your preferred default value
    taskarray: '',
  };

  const navigate = useNavigate();

  const makeApiCall = async (values) => {
    const url = process.env.REACT_APP_API_URL + 'syllabus/AddSyllabus';

    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.post(url, values, { headers });
      //console.log(response);
      toast.success('Syllabus Added Successfully!', {
        position: 'top-center',
        autoClose: 1000,
        theme: 'colored',
      });
      setTimeout(() => {
        navigate('/SyllabusList');
      }, 1200);
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <div>
      <h4 className=''>Add New Syllabus</h4>
      <Formik
        initialValues={formValues}
        onSubmit={async (values) => {
          makeApiCall(values);
        }}
      >
        <div className='form-container'>
          <Form className=''>
            <div>
              <label>Syllabus Name</label>
              <Field name='syllabusname' type='text' />
            </div>
            <div>
              <label>Course ID</label>
              <Field name='courseid' type='number' />
            </div>
            <div>
              <label>Task Array (JSON)</label>
              <Field name='taskarray' as='textarea' />
            </div>
            <div>
              <button type='submit' className='btn btn-success'>
                Create Syllabus
              </button>
            </div>
          </Form>
        </div>
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default SyllabusAdd;
