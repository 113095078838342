import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import JoditEditor from 'jodit-react';


const notifydata = {
  position: 'top-center',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

const successNotify = () => toast.success('Assignment updated Successfully!', notifydata);
const errNotify = () => toast.error('Assignment not updated!', notifydata);

const AssignmentUpdate = () => {
  const { id } = useParams();

  const userDataInitialState = {
    taskid: id,
    taskname: '',
    classid: '',
    startdate: '',
    enddate: '',
    taskcontent: '',
  };

  const [classList, setClassList] = useState([]);

  const navigate = useNavigate();

  const callApiClassList = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const url = process.env.REACT_APP_API_URL + 'classes/classeslist';
      const response = await axios.get(url, { headers });
      setClassList(response.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const [updateData, setUpdateData] = useState(userDataInitialState);

  const callAssignmentUpdateApi = async (data) => {
    try {

      const url = process.env.REACT_APP_API_URL + 'assignments/edit';
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.put(url, data, { headers });
      successNotify();
      setTimeout(() => {

        navigate('/assignmentslist');
      }, 1200);
    } catch (error) {
      errNotify();

    }
  };

  const getAssignmentByTaskid = async (id) => {
    const url = process.env.REACT_APP_API_URL + 'assignments/search/' + id;
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      return userDataInitialState;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        callApiClassList();
        const data = await getAssignmentByTaskid(id);
        let startDateObj = new Date(data[0].startdate);
        let startDate = startDateObj.getDate();
        let startMonth = startDateObj.getMonth() + 1;
        let startYear = startDateObj.getFullYear();

        let formattedStartDate = `${startYear}-${startMonth.toString().padStart(2, '0')}-${startDate
          .toString()
          .padStart(2, '0')}`;

        let endDateObj = new Date(data[0].enddate);
        let endDate = endDateObj.getDate();
        let endMonth = endDateObj.getMonth() + 1;
        let endYear = endDateObj.getFullYear();

        let formattedEndDate = `${endYear}-${endMonth.toString().padStart(2, '0')}-${endDate
          .toString()
          .padStart(2, '0')}`;

        setUpdateData({
          ...data[0],
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        });
      } catch (error) {
        // console.error(error);
      }
    })();
  }, [id]);

  const [updateAssignment, setUpdateAssignment] = useState('');
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    errNotify();
  };

  const handleClickUpdate = (values) => {
    setUpdateAssignment(values);
    setShow(true);
  };

  const handleUpdateItem = () => {
    callAssignmentUpdateApi(updateAssignment);
    setShow(false);
  };

  const handleEditorChange = (content) => {
    setUpdateData({ ...updateData, taskcontent: content });
  };

  return (
    <div>


      <h4 className="">Update Assignment Details</h4>
      <Formik
        enableReinitialize={true}
        initialValues={updateData}
        onSubmit={async (values) => {
          handleClickUpdate(values);
        }}
      >
        <div className="form-container">
          <Form>
            <Field hidden type="number" name="taskid" />
            <label htmlFor="taskname">Task Name:</label>
            <Field required name="taskname" type="text" maxLength={100} />
            <br />
            <label htmlFor="classid">Class ID</label>
            <select
              required
              name="classid"
              type="number"
              value={updateData.classid}
              onChange={(e) => setUpdateData({ ...updateData, classid: e.target.value })}
            >
              <option value="">Select a Class</option>
              {classList &&
                classList.map((classItem) => (
                  <option key={classItem.classid} value={classItem.classid}>
                    {classItem.classid} - {classItem.classname}
                  </option>
                ))}
            </select>
            <br />
            <label htmlFor="startdate">Start Date:</label>
            <Field name="startdate" type="date" />
            <br />
            <label required htmlFor="enddate">
              End Date:
            </label>
            <Field name="enddate" type="date" />
            <br />

            {/* Jodit Editor */}
            <div>
              <label htmlFor="taskcontent">Task Content:</label>
              <JoditEditor
                value={updateData.taskcontent}
                onChange={(content) => handleEditorChange(content)}
                tabIndex={1}
              />
            </div>

            <br />
            <button type="submit" className="btn btn-warning">
              Update Assignment
            </button>
          </Form>
        </div>
      </Formik>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirm Update?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sure you want to Update Assignment ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdateItem}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />

    </div>
  );
};

export default AssignmentUpdate;
