import React, { useContext, useEffect, useState } from "react";
import { DataAppContext } from "../DataContext";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo_full.png";

const Header = (props) => {
  const localContext = useContext(DataAppContext);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { isMobileMenu, setMobileMenu } = props;

  const { appstate, logout_user } = localContext;
  const { loginstatus } = appstate;

  const studentpages = ["/myStatus", "/class", "/assignment", "/dashboard", "/batchclass", "/assignmentall", "/allclasses", "/userprofile", "/myattendance"];
  const teacherpages = [];

  const checkLoginandRedirect = () => {
    const token = localStorage.getItem("jwttoken");
    if (!token) {
      navigate("/login");
    }
  };

  const checkUserTypeandRedirect = () => {
    const usertype = localStorage.getItem("usertype");
    if (usertype === "student") {
      let pageurl = window.location.pathname;
      if (studentpages.indexOf(pageurl) === -1) {
        navigate("/accessdenied");
      }
    }
  };
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setMobileMenu(isMobileMenu => !isMobileMenu);
  };

  useEffect(() => {
    checkLoginandRedirect();
    checkUserTypeandRedirect();
  }, []);
  return (
    <>
      <header id="header" class="header">
        <div class="top-left">
          <div class="navbar-header">
            <a class="navbar-brand" href="./">
              <img src={logo} alt="Logo" />
            </a>
            <a class="navbar-brand hidden" href="./">
              <img src={logo} alt="Logo" />
            </a>
            <a id="menuToggle" class="menutoggle" onClick={togglePopup}>
              <i class="fa fa-bars"></i>
            </a>
          </div>
        </div>
        <div class="top-right">
          <div class="header-menu">
            <div className="user-area dropdown float-right">
              <div>
                {loginstatus && (
                  <button
                    className="btn btn-outline-danger"
                    onClick={logout_user}
                  >
                    Logout
                  </button>
                )}
                {!loginstatus && window.location.pathname !== "/" && (
                  <Link className="btn btn-outline-success" to="/login">
                    Login
                  </Link>
                )}
              </div>
            </div>
            {/* <div class="header-left">
                            <button class="search-trigger"><i class="fa fa-search"></i></button>
                            <div class="form-inline">
                                <form class="search-form">
                                    <input class="form-control mr-sm-2" type="text" placeholder="Search ..." aria-label="Search" />
                                    <button class="search-close" type="submit"><i class="fa fa-close"></i></button>
                                </form>
                            </div>

                            <div class="dropdown for-notification">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="notification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-bell"></i>
                                    <span class="count bg-danger">3</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="notification">
                                    <p class="red">You have 3 Notification</p>
                                    <a class="dropdown-item media" href="#">
                                        <i class="fa fa-check"></i>
                                        <p>Server #1 overloaded.</p>
                                    </a>
                                    <a class="dropdown-item media" href="#">
                                        <i class="fa fa-info"></i>
                                        <p>Server #2 overloaded.</p>
                                    </a>
                                    <a class="dropdown-item media" href="#">
                                        <i class="fa fa-warning"></i>
                                        <p>Server #3 overloaded.</p>
                                    </a>
                                </div>
                            </div>

                            <div class="dropdown for-message">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="message" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-envelope"></i>
                                    <span class="count bg-primary">4</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="message">
                                    <p class="red">You have 4 Mails</p>
                                    <a class="dropdown-item media" href="#">
                                        <span class="photo media-left"><img alt="avatar" src="images/avatar/1.jpg" /></span>
                                        <div class="message media-body">
                                            <span class="name float-left">Jonathan Smith</span>
                                            <span class="time float-right">Just now</span>
                                            <p>Hello, this is an example msg</p>
                                        </div>
                                    </a>
                                    <a class="dropdown-item media" href="#">
                                        <span class="photo media-left"><img alt="avatar" src="images/avatar/2.jpg" /></span>
                                        <div class="message media-body">
                                            <span class="name float-left">Jack Sanders</span>
                                            <span class="time float-right">5 minutes ago</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        </div>
                                    </a>
                                    <a class="dropdown-item media" href="#">
                                        <span class="photo media-left"><img alt="avatar" src="images/avatar/3.jpg" /></span>
                                        <div class="message media-body">
                                            <span class="name float-left">Cheryl Wheeler</span>
                                            <span class="time float-right">10 minutes ago</span>
                                            <p>Hello, this is an example msg</p>
                                        </div>
                                    </a>
                                    <a class="dropdown-item media" href="#">
                                        <span class="photo media-left"><img alt="avatar" src="images/avatar/4.jpg" /></span>
                                        <div class="message media-body">
                                            <span class="name float-left">Rachel Santos</span>
                                            <span class="time float-right">15 minutes ago</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> */}

            {/* <div class="user-area dropdown float-right">
              <div>
                {loginstatus && (
                  <button
                    className="btn btn-outline-danger"
                    onClick={logout_user}
                  >
                    Logout
                  </button>
                )}
                {!loginstatus && window.location.pathname !== "/" && (
                  <Link className="btn btn-outline-success" to="/login">
                    Login
                  </Link>
                )}
              </div> */}
            {/* <a href="#" class="dropdown-toggle active" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img class="user-avatar rounded-circle" src="images/admin.jpg" alt="User Avatar" />
                            </a>

                            <div class="user-menu dropdown-menu">
                                <a class="nav-link" href="#"><i class="fa fa- user"></i>My Profile</a>

                                <a class="nav-link" href="#"><i class="fa fa- user"></i>Notifications <span class="count">13</span></a>

                                <a class="nav-link" href="#"><i class="fa fa -cog"></i>Settings</a>

                                <a class="nav-link" href="#"><i class="fa fa-power -off"></i>Logout</a>
                            </div> */}
            {/* </div> */}
          </div>
        </div>
      </header>
      {/* {isPopupOpen && (
        <div className="popup-window">
          <div className="popup-content">
            <button className="close-popup" onClick={togglePopup}>
              &times;
            </button>
            <nav>
              <ul className="popup-menu">
                <label>
                  <h2>Admin Option</h2>
                </label>
                <li className="popup-item">
                  <Link to="/userslist">Users List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/mernuserslist">MERN Users List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/mernregister">XXX - MERN Register</Link>
                </li>
                <li className="popup-item">
                  <Link to="/classlist">Class List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/attendancemern">Attendance MERN</Link>
                </li>
                <li className="popup-item">
                  <Link to="/syllabuslist">Syllabus List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/assignmentslist">Assignment List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/updateregisteredusers">
                    XXX - Edit 3 Col - Reg Users
                  </Link>
                </li>
                <li className="popup-item">
                  <Link to="/users_registered">Registered Users List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/internslist">Tech Interns List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/mbainternslist">MBA Interns List</Link>
                </li>
                <li className="popup-item">
                  <Link to="/marksextra">Assign Extra Marks</Link>
                </li>
                <label>
                  <h2>Student Option</h2>
                </label>
                <li className="popup-item">
                  <Link to="/dashboard">DashBoard</Link>
                </li>{" "}
                <li className="popup-item">
                  <Link to="/class">Class Schedule</Link>
                </li>{" "}
                <li className="popup-item">
                  <Link to="/assignment">Assignment</Link>
                </li>{" "}
                <li className="popup-item">
                  <Link to="/quiz">Quiz</Link>
                </li>{" "}
                <li className="popup-item">
                  <Link to="/leaderboard">LeaderBoard</Link>
                </li>{" "}
                <li className="popup-item">
                  <Link to="/allStatus">myStatus</Link>
                </li>
                <label>
                  <h2>Mock Option</h2>
                </label>
                <li className="popup-item">
                  <Link to="/mocks">Mocks List</Link>
                </li>
                <li className="popup-item">
                  <Link to="dashboard">Mocks DashBoard</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Header;
