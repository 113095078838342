import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import JoditEditor from 'jodit-react';

const notifydata = {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}
const successNotify = () => toast.success("Assignment Submited Successfully!", notifydata);
const errNotify = () => toast.error("Assignment Not Submited!", notifydata);

function AssignmentSubmit() {
  const { assgid } = useParams();
  const navigate = useNavigate();
  const [userid, setUserId] = useState('');
  const [taskid, setTaskId] = useState(assgid);
  const [taskanswer, setTaskAnswer] = useState('');
  const [show, setShow] = useState(false);
  const [courseid, setCourseID] = useState(false);

  const editor = useRef(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userid');
    if (storedUserId) {
      setUserId(storedUserId);
    }
    else {
      navigate('/login')
    }
  }, []);

  const handleSubmit = async () => {
    if (taskanswer) {
      //   const plainTextAnswer = taskanswer.replace(/<[^>]*>/g, '');
      //   const cleanAnswer = plainTextAnswer.replace(/&nbsp;/g, ' ');
      const url = process.env.REACT_APP_API_URL + 'assignments/submittaskanswer';
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.post(url, {
        userid,
        taskanswer,
        taskid,
        courseid,
      }, { headers });
      successNotify();
      setTaskAnswer('');
      setTimeout(() => {
        navigate(`/assignment/${assgid}`)
      }, 1300);
    } else {
      errNotify();
    }
  }
  const handleClose = () => {
    setShow(false);
    errNotify();
  };
  const openSubmit = () => {
    setShow(true);
  };
  const handleSubmitItem = () => {
    handleSubmit();
    setShow(false);
  };
  const getAssignmentByTaskid = async (id) => {
    const url = process.env.REACT_APP_API_URL + 'assignments/search/' + id;
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      setCourseID(response.data[0].courseid);
      // console.log(response.data[0].courseid);
    } catch (error) {
      // console.log(error)
    }
  };
  useEffect(() => {
    getAssignmentByTaskid(assgid);
  }, [assgid]);


  return (
    <>
      <div>
        <h2>Assignment Submission</h2>
        <form >
          <div>
            <input hidden type="text" id="userid" value={userid} />
          </div>
          <div>
            <label htmlFor="taskanswer">Task Answer:</label>
            <JoditEditor
              ref={editor}
              value={taskanswer}
              onChange={newContent => setTaskAnswer(newContent)}
            />
          </div>
          <div>
            <input hidden required id="taskid" value={taskid} />
          </div>
          <div>
            <input hidden required id="courseid" value={courseid} />
          </div>
          <button type="button" className='btn btn-success' onClick={() => openSubmit()}>Submit Assignment</button>
        </form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Confirm Submit?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sure you want to Submit Assignment ?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmitItem}>
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
}

export default AssignmentSubmit;
