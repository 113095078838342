import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import loadImage from "../images/giphy.gif";

const AllStatus = () => {
  const [userList, setUserList] = useState([]);
  const [searchUsername, setSearchUsername] = useState("");
  const [loader, setLoader] = useState(true);
  const { userId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const headers = {
          'x-access-token': localStorage.getItem('jwttoken')
        };
        setLoader(true);
        const response = await axios.get(process.env.REACT_APP_API_URL + 'classes/bothTableactivateUsers', { headers });
        setUserList(response.data.rows);
        setLoader(false);

      } catch (error) {
        setLoader(false);
      }
    };

    fetchUserList();
  }, [userId]);

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <h2 className="mb-4">Check Your Status</h2>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>Username</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user) => (
                <tr key={user.username}>
                  <td>
                    <Link
                      to={`/myStatus/${user.username}`}
                      className="btn btn-outline-info"
                      data-mdb-ripple-color="light"
                    >
                      {user.username}
                    </Link>
                  </td>
                  {/* <td>{user.username}</td> */}
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AllStatus;
