import React, { useState, useEffect } from "react";
import axios from "axios";
import FormFeedback from "./FormFeedback";
import loadImage from "../images/giphy.gif";
import VideoModal from './VideoModal';

const Classes = () => {
  const [classList, setClassList] = useState([]);
  const [classid, setClassid] = useState("");
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');


  const callClassList = async () => {
    try {
      setLoader(true);
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const registered_courses = localStorage.getItem('registered_courses')
      const array = registered_courses.split(',').map(item => item.trim());
      const url = process.env.REACT_APP_API_URL + 'classes/classeslistByID';
      const response = await axios.post(url, { courseIDs: array }, { headers });
      //console.log(response);
      setClassList(response.data);
      setLoader(false);
    }
    catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  const callAttendanceApi = async (classid) => {
    try {
      setLoader(true);
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      let obj = {};
      obj.userid = localStorage.getItem('userid');
      obj.classid = classid;
      const url = process.env.REACT_APP_API_URL + 'attendance/markjoining/';
      const response = await axios.post(url, obj, { headers });
      //console.log(response);
      setClassList(response.data);
      setLoader(false);
    }
    catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  const openClass = (link, classid) => {
    callAttendanceApi(classid);
    // setClassStatus(true);
    window.open(link, "_blank");
  };

  const openFeedback = (classid) => {
    //console.log(classid);
    setClassid(classid);
    setModal(true);
  };

  useEffect(() => {
    callClassList();
  }, []);

  const dateCompare = (dateDbInput = "2023-10-05T18:30:00.000Z") => {
    //let dateDb =  '2023-10-05T18:30:00.000Z';
    let dateDb = dateDbInput.slice(0, 10);
    //console.log('datedb', dateDb);
    //let dateToday = new Date().toJSON().slice(0,10);
    //console.log('dateToday', dateToday);
    let status = 0;

    let dateTodayObj = {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };

    let dateDbObj = {
      date: parseInt(dateDb.slice(8, 10)),
      month: parseInt(dateDb.slice(5, 7)),
      year: parseInt(dateDb.slice(0, 4)),
    };

    // console.log(dateTodayObj)
    // console.log(dateDbObj)

    if (dateTodayObj.year > dateDbObj.year) {
      //console.log('dateTodayObj is greater.')
      status = -1;
    } else if (dateTodayObj.year < dateDbObj.year) {
      //console.log('dateDbObj is greater.')
      status = 1;
    } else {
      if (dateTodayObj.month > dateDbObj.month) {
        //console.log('dateTodayObj is greater.')
        status = -1;
      } else if (dateTodayObj.month < dateDbObj.month) {
        //console.log('dateDbObj is greater.')
        status = 1;
      } else {
        if (dateTodayObj.date > dateDbObj.date) {
          //console.log('dateTodayObj is greater.')
          status = -1;
        } else if (dateTodayObj.date < dateDbObj.date) {
          //console.log('dateDbObj is greater.')
          status = 1;
        } else {
          //console.log('Both are same.')
          status = 0;
        }
      }
    }
    return status;
  };

  const printDate = (dateDb) => {
    let date = parseInt(dateDb.slice(8, 10));
    let month = parseInt(dateDb.slice(5, 7));
    let year = parseInt(dateDb.slice(0, 4));

    return `${date} - ${month} - ${year}`;
  };

  const handleShowModal = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentVideoUrl('');
  };

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Topic</th>
                <th>Course</th>
                <th>Date</th>
                <th>Class Link</th>
                {/* <th>Feedback</th> */}
                <th>Recording</th>
                <th>Source Code</th>
                <th>Articles</th>
              </tr>
            </thead>
            <tbody>
              {classList[0] &&
                classList.map((item, index) => (
                  <tr key={index + item.classid}>
                    <td>{index + 1}</td>
                    <td>{item.classname}</td>
                    <td>{item.coursename}</td>
                    <td>{printDate(item.startdate)}</td>
                    {/* <td>{item.classlink}</td> */}
                    <td>
                      {dateCompare(item.startdate) === 0 && (
                        <button
                          onClick={() =>
                            openClass(item.classlink, item.classid)
                          }
                          className="btn btn-primary"
                        >
                          Join
                        </button>
                      )}
                    </td>
                    {/* <td>
                      {dateCompare(item.startdate) < 0 && (
                        <button
                          onClick={() => openFeedback(item.classid)}
                          className="btn btn-primary"
                        >
                          Feedback & Mark Attendance
                        </button>
                      )}
                    </td> */}
                    <td>
                      {item.videolink ? (
                      <button
                        onClick={() => handleShowModal(item.videolink)}
                        className="btn btn-warning"
                      >
                        Class Recording
                      </button>
                      ) : (
                        <button
                          className="btn btn-warning"
                          disabled
                          style={{ filter: "blur(2px)" }}
                        >
                          Class Recording
                        </button>
                      )}
                    </td>
                    <td>
                      {item.codelink ? (
                        <a
                          href={item.codelink}
                          target="_blank"
                          className="btn btn-success"
                        >
                          Code Link
                        </a>
                      ) : (
                        <button
                          className="btn btn-success"
                          disabled
                          style={{ filter: "blur(2px)" }}
                        >
                          Code Link
                        </button>
                      )}
                    </td>
                    <td>
                      {item.articlelinks ? (
                        <a
                          href={item.articlelinks}
                          target="_blank"
                          className="btn btn-info"
                        >
                          Article link
                        </a>
                      ) : (
                        <button
                          className="btn btn-info"
                          disabled
                          style={{ filter: "blur(2px)" }}
                        >
                          Article link
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
               <VideoModal
               show={showModal}
               handleClose={handleCloseModal}
               videoUrl={currentVideoUrl}
               />                
            </tbody>
          </table>
          <br></br>

          {modal && (
            <>
              <div class="modal">
                <div class="modal-dialog classes-modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h2>Feedback Form</h2>
                      <div>Rate from 1 to 5 - 1 Lowest and 5 Highest</div>
                    </div>
                    <FormFeedback classid={classid} />
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={() => setModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Classes;
