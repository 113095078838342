import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AccessDenied = (props) => {
    return (
        <>
            <div className='row maincontent'>
                <div className='col-4'>

                </div>
                <div className='col-4'>
                    <h2 className='text-center'>You are not allowed to visit the page</h2>
                    <Link to='/dashboard' className='btn btn-primary'>Go to Dashboard</Link>
                    
                </div>
                <div className='col-4'>

                </div>
               
            </div>
        </>
    )
}

export default AccessDenied
