import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import loadImage from "../images/giphy.gif";

const MbaInternsList = () => {
  const [userList, setUserList] = useState([]);
  const [filterCourseId, setFilterCourseId] = useState('');
  const [loader, setLoader] = useState(true);
  const { userId } = useParams();

  const headers = {
    "x-access-token": localStorage.getItem("jwttoken"),
  };

  const fetchUserList = async () => {
    try {
      setLoader(true);
      const response = await axios.get(process.env.REACT_APP_API_URL + 'course/mbainternslist', { headers });

      setUserList(response.data);
      setLoader(false);

      // Assuming your API returns an array of user data
    } catch (error) {
      console.error('Error fetching user details:', error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, [userId]);

  let filteredUserList = filterCourseId ? userList.filter(user => user.courseid.toLowerCase() === filterCourseId) : userList;
  filteredUserList = filteredUserList.reverse()


  // const convertTechIntern = async(userid) => {
  //   try {
  //     const response = await axios.put(process.env.REACT_APP_API_URL + 'users/convertTechIntern/' + userid );
  //     fetchUserList();
  //   } catch (error) {
  //     console.error('Error converting user', error);
  //   }
  // }


  const [enddate, setEndDate] = useState('');
  const [duration, setDuration] = useState("");
  const [empid, setEmpId] = useState('');
  const [certino, setCertiNo] = useState('');
  const [gender, setGender] = useState('');
  const [userid, setUserid] = useState('');
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false)
  };

  const completeTechIntern = (userid) => {
    setUserid(userid);
    setShowModal(true);
  }

  const apiCompleteTechIntern = async () => {
    let obj = {};
    obj.userid = userid;
    obj.enddate = enddate;
    obj.duration = duration;
    obj.empid = empid;
    obj.certino = certino;
    obj.gender = gender;
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + 'users/completeTechIntern', obj, { headers });
      fetchUserList();
      setShowModal(false)
    } catch (error) {
      console.error('Error converting user', error);
    }
  }

  const markcertidelivered = async (userid) => {
    let obj = {};
    obj.userid = userid;
    obj.completionStatus = "Completed";
    obj.certiDeliverStatus = "Delivered";
    try {
      const response = await axios.put(
        process.env.REACT_APP_API_URL + "course/markCertificateDelivered",
        obj,
        { headers }
      );
      fetchUserList();
      setShowModal(false);
    } catch (error) {
      console.error("Error converting user", error);
    }
  };

  const updateDocumentStatus = async (userid) => {
    try {
      const updatedUserList = userList.map((user) => {
        if (user.userid === userid) {
          return { ...user, documentStatus: "Approved" };
        }
        return user;
      });
      setUserList(updatedUserList);

      await axios.post(
        process.env.REACT_APP_API_URL + "course/docapprove",
        { userid, docstatus: "Approved" },
        { headers }
      );

      // Fetch the user list again after updating document status
      fetchUserList();
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  const updateTaskAttendanceStatus = async (userid) => {
    try {
      const updatedUserList = userList.map((user) => {
        if (user.userid === userid) {
          return { ...user, attendancestatus: "Approved" };
        }
        return user;
      });
      setUserList(updatedUserList);

      await axios.post(
        process.env.REACT_APP_API_URL + "course/attendanceapprove",
        { userid, attendancestatus: "Approved" },
        { headers }
      );

      // Fetch the user list again after updating document status
      fetchUserList();
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  const updateFeeStatus = async (userid) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "course/feeapprove",
        { userid, feestatus: "Approved" },
        { headers }
      );
      fetchUserList();
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  const updateExitStatus = async (userid) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "course/exitapprove",
        { userid, exitstatus: "Approved" },
        { headers }
      );
      fetchUserList();
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  return (
    <div>

      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="loader" />
        </div>
      ) : (
        <>
          <h2 className="mb-4">User Details</h2>

          <select value={filterCourseId} onChange={(e) => setFilterCourseId(e.target.value)} className="p-2 mb-4">
            <option value="">All Course IDs</option>
            <option value="mern">MERN</option>
            <option value="plac">PLAC</option>
            <option value="mbai">MBAI</option>
            <option value="mbap">MBAP</option>
            <option value="techintern">TechIntern</option>
            <option value="tech">Tech</option>
            <option value="mock">Mock</option>
            <option value="inte">Inte</option>
          </select>

          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User ID</th>
                <th>Date</th>
                <th>Emp ID</th>
                <th className='bg-warning-subtle'>Username</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th className='bg-warning-subtle'>Password</th>
                <th>WhatsApp</th>
                <th>City</th>
                <th>Hometown</th>
                <th>College</th>
                <th>University</th>
                <th>Passing Year</th>
                <th>Branch</th>
                <th>Course ID</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Certi ID</th>
                <th>Certi Status</th>
                <th>Action</th>
                
              </tr>
            </thead>
            <tbody>
              {filteredUserList.map((user, index) => (
                <tr key={index + user.user_id}>
                  <td>{index + 1}</td>
                  <td><Link to={`/users_registered_detail/${user.user_id}`} className="btn btn-outline-info" data-mdb-ripple-color="light">{user.user_id}</Link></td>
                  <td>{user.regdate}</td>
                  <td>{user.employeeid}</td>
                  <td className='bg-warning-subtle'>{user.username}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.mobile}</td>
                  <td className='bg-warning-subtle'>{user.password}</td>
                  <td>{user.whatsapp}</td>
                  <td>{user.city}</td>
                  <td>{user.hometown}</td>
                  <td>{user.college}</td>
                  <td>{user.university}</td>
                  <td>{user.passing_year}</td>
                  <td>{user.branch}</td>
                  <td>{user.courseid}</td>
                  <td>{user.startdate}</td>
                  <td>{user.enddate}</td>
                  <td>{user.certificateid}</td>
                  <td className={user.certiDeliverStatus === 'Requested' ? 'btn btn-danger': 'btn btn-primary'}>{user.certiDeliverStatus}</td>
                  {/* <td><button className='btn btn-success' disabled={user.courseid === 'emp_techintern'} onClick={() => convertTechIntern(user.user_id)}>Convert to Intern</button></td> */}
                  <td> <Link
                      className="btn btn-success"
                      to={`/offerlettermba/${user.user_id}`} >
                      OfferLetter </Link>
                  </td> <td> <button
                      className="btn btn-primary"
                      onClick={() => updateDocumentStatus(user.user_id)}
                      disabled={user.docstatus === "Approved"}
                    >
                      Approve Document </button>
                  </td>  <td> <button
                      className="btn btn-primary"
                      onClick={() => updateTaskAttendanceStatus(user.user_id)}
                      disabled={user.attendancestatus === "Approved"} >
                      Approve Task and Attendance
                    </button> </td>
                  <td> <button
                      className={
                        user.feestatus === "Due"
                          ? "btn btn-danger"
                          : "btn btn-primary" }
                      onClick={() => updateFeeStatus(user.user_id)}
                      disabled={user.feestatus === "Approved"} >
                      {user.feestatus === "Due"
                        ? "Fee Due - Collect & Approve"
                        : "Approve Fee"}
                    </button> </td>
                  <td> <button
                      className="btn btn-primary"
                      onClick={() => updateExitStatus(user.user_id)}
                      disabled={user.exitstatus === "Approved"} >
                      Approve Exit Formality
                    </button> </td>
                  <td> <Link
                      className="btn btn-success"
                      to={`/offlineofferletter/${user.user_id}`} >
                      Offline OfferLetter
                    </Link>
                  </td><td>
                    <button
                      className="btn btn-warning"
                      onClick={() => completeTechIntern(user.user_id)}
                      disabled={
                        !(
                          user.docstatus === "Approved" &&
                          user.attendancestatus === "Approved" &&
                          user.feestatus === "Approved" &&
                          user.exitstatus === "Approved" )}>
                      Generate Exp Letter
                    </button>
                  </td>
                  {user.enddate && (
                    <td>
                      <Link
                        className="btn btn-success"
                        to={`/experiencelettermba/${user.user_id}`} >
                        Experience Letter
                      </Link>
                    </td> )}
                  {user.enddate && (
                    <td>
                      <button
                        className="btn btn-success"
                        onClick={() => markcertidelivered(user.user_id)}
                        disabled={user.certiDeliverStatus === "Delivered"} >
                        Click to mark Certificate Delivered
                      </button>
                    </td>
                    )}
                </tr>
              ))}
            </tbody>
          </table>


 
            <Modal show={showModal} >
            <Modal.Header>
              <Modal.Title>Generate Exp Letter - MBA Intern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <label>Start Date:</label>
              <input type='date' placeholder='End Date' onChange={(e) => setEndDate(e.target.value)} />
              <br></br>
              {/* <input type='text' placeholder='Employee ID' onChange={(e) => setEmpId(e.target.value)} /> */}
              <label>Duration:</label>
              <input

                type="text"
                placeholder="Duration"
                onChange={(e) => setDuration(e.target.value)}
              />
              <br></br>
              <label>Gender:</label>
              {/* <input type='text' placeholder='Certificate No' onChange={(e) => setCertiNo(e.target.value)} />
              <br></br>
              <input type='text' placeholder='Gender (male/female)' onChange={(e) => setGender(e.target.value)} /> */}

              <select onChange={(e) => setGender(e.target.value)}>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <br></br>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="button" onClick={() => apiCompleteTechIntern()} variant="primary" style={{ marginLeft: '10px' }}>
                Generate
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default MbaInternsList;
