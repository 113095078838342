import React, { useContext, useEffect, useState } from 'react';
import { DataAppContext } from '../../src/DataContext';
import axios from 'axios';

const StudentStatus = () => {
  const { appstate } = useContext(DataAppContext);
  const [userId, setUserId] = useState(null);
  const [classes, setClasses] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [videolinks, setVideolinks] = useState([]);

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem('userid');
    setUserId(userIdFromStorage);
    fetchData(userIdFromStorage);
    fetchvideolink();
  }, []);

  const fetchData = async (Id) => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const res = await axios.post(process.env.REACT_APP_API_URL + 'classes/mystatus', { Id }, { headers });
      if (res.status === 200) {
        setClasses(res.data.classes);
        setAssignments(res.data.assignments);
      }
    } catch (error) {
      { }
    }
  };

  const fetchvideolink = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const res = await axios.get(process.env.REACT_APP_API_URL + 'classes/videolinks', { headers });
      if (res.status === 200 && Array.isArray(res.data.videolinks)) {
        setVideolinks(res.data.videolinks);
      } else {
        // console.error('Invalid videolinks data:', res.data);
      }
    } catch (error) {
      { }
    }
  };

  const getBackgroundColor = (status) => {
    return status === 'You have not attended this class yet' || status === 'You have not attempted this task yet'
      ? 'table-danger'
      : '';
  };

  const handleWatchVideoClick = (videolink) => {
    window.open(videolink);
  };

  return (
    <div className="container mt-5">
      <div>
        <h1>Classes</h1>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Class Name</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {classes.map((classItem, index) => (
              <tr key={index} className={getBackgroundColor(classItem.class_status)}>
                <td>{classItem.classname}</td>
                <td>{classItem.class_status}</td>
                <td>
                  {videolinks[index] && (
                    <button
                      className="btn btn-primary"
                      onClick={() => handleWatchVideoClick(videolinks[index].videolink)}
                    >
                      Watch Class Video
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h1>Assignments</h1>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Task Name</th>
              <th scope="col">Marks Obtained</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((assignment, index) => (
              <tr key={index} className={getBackgroundColor(assignment.marksobtained)}>
                <td>{assignment.taskname}</td>
                <td>{assignment.marksobtained}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentStatus;
