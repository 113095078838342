import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router';

const ClassAdd = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  const formValues = {
    classname: "",
    coursename: "",
    startdate: "",
    starttime: "",
    enddate: "",
    classlink: "",
    videolink: "",
    quizid: "",
    topicid: "",
  };

  const makeApiCall = async (values) => {
    const headers = {
      'x-access-token': localStorage.getItem('jwttoken')
    };
    const url = process.env.REACT_APP_API_URL + 'classes/addclasses';
    console.log(values); // Log values to debug
    const response = await axios.post(url, values, { headers });
    alert('Class Added Successfully');
    navigate('/classlist');
  };

  const getCourses = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const url = process.env.REACT_APP_API_URL + "course/getallcourses"
      
      const response = await axios.get(url, { headers });
      console.log(response.data); // Debugging: check the data
      setCourses(response.data);
    } catch (error) {
      console.log('Error fetching courses:', error);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <div>
      <h4>Add New Class</h4>
      <Formik
        initialValues={formValues}
        onSubmit={async (values) => {
          console.log(values); // Log form values
          await makeApiCall(values);
        }}
      >
        <Form className='form-container'>
          <div>
            <label>Class Name</label>
            <Field name="classname" type="text" />
          </div>
          <div>
            <label>Course Name</label>
            <Field as="select" name="coursename">
  <option value="">Select a Course</option>
  {courses.map((course, index) => {
    console.log('Dropdown Option:', course.coursetitle); // Check what is being rendered
    return (
      <option key={index} value={course.courseid}>
        {course.courseid}
      </option>
    );
  })}
</Field>

          </div>
          <div>
            <label>Start Date</label>
            <Field name="startdate" type="date" />
          </div>
          <div>
            <label>Start Time</label>
            <Field name="starttime" type="time" />
          </div>
          <div>
            <label>End Date</label>
            <Field name="enddate" type="date" />
          </div>
          <div>
            <label>Class Link</label>
            <Field name="classlink" type="text" />
          </div>
          <div>
            <label>Video Link</label>
            <Field name="videolink" type="text" />
          </div>
          <div>
            <label>Quiz Id</label>
            <Field name="quizid" type="number" />
          </div>
          <div>
            <label>Topic Id</label>
            <Field name="topicid" type="text" />
          </div>
          <div>
            <button type="submit" className='btn btn-success'>Create Class</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ClassAdd;
