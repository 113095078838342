import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
const _ = require('lodash');

const SyllabusUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  var syllabusDataInitialState = {
    syllabusid: id,
    syllabusname: '',
    courseid: '',
    taskarray: '',
  };
  const [updateData, setUpdateData] = useState(syllabusDataInitialState);

  const callApiSyllabusEdit = async (values) => {
    values.syllabusid = id;
    const url = process.env.REACT_APP_API_URL + 'syllabus/update';
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.put(url, values, { headers });
      //console.log(response);
      navigate('/syllabuslist'); // Redirect to the syllabus list page after updating
    } catch (error) {
      // console.error(error);
      // Handle error, show an error message, or perform other actions as needed
    }
  };

  const callApiSyllabusDetails = async (syllabusid) => {
    const url = process.env.REACT_APP_API_URL + 'syllabus/search/' + id;
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      setUpdateData(response.data[0]);
      // console.log(response);
    } catch (error) {
      // console.error(error);
      // Handle error, show an error message, or perform other actions as needed
    }
  };

  useEffect(() => {
    (async () => {
      let data = await callApiSyllabusDetails(id);
      // SetUpdateData(data);
    })();
  }, []);

  // console.log('updateData-', updateData);

  return (
    <>
      <h4 className="SyllabusUpdate-heading">Update Syllabus</h4>
      <Formik
        enableReinitialize={true}
        initialValues={updateData}
        onSubmit={async (values) => {
          if ((values === updateData)) {
            alert('No updates made');
          } else {
            const confirmation = window.confirm('Are you sure you want to update the syllabus?');
            if (confirmation) {
              // Perform the API call to update the syllabus
              callApiSyllabusEdit(values);
            } else {
              alert('Update canceled');
            }
          }
          // console.log(values);
        }}
      >
        <div className="form-container">
          <Form className="SyllabusUpdate-form">
            <label>Syllabus Name</label>
            <Field name="syllabusname" type="text" />
            <br />
            <label>Course ID</label>
            <Field name="courseid" type="text" />
            <br />
            <label>Task Array</label>
            <Field name="taskarray" type="text" />
            <br />
            <button type="submit" className="btn btn-success">
              Update Syllabus
            </button>
          </Form>
        </div>
      </Formik>
    </>
  );
};

export default SyllabusUpdate;
