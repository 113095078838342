// VideoModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const getYouTubeEmbedUrl = (url) => {
  if (!url) {
    console.error('Invalid YouTube URL');
    return null;
  }

  const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regex);

  if (!match) {
    console.error('Invalid YouTube URL format');
    return null;
  }

  return `https://www.youtube.com/embed/${match[7]}`;
};

const VideoModal = ({ show, handleClose, videoUrl }) => {
  const embedUrl = getYouTubeEmbedUrl(videoUrl);

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" fullscreen aria-labelledby="contained-modal-title-vcenter" >
      <Modal.Header>
        <Modal.Title className="w-100 d-flex justify-content-between align-items-center">
          <span>Class Recording</span>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          overflow: 'hidden'
        }}>
          <iframe
            src={embedUrl}
            style={{
              borderRadius: '15px',
              width: '100%',
              height: '100%',
              maxWidth: 640, // set a maximum width
              maxHeight: 480 // set a maximum height
            }}
            allowFullScreen
            title="Class Recording"
          ></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoModal;