import React, { useEffect, useState,useRef  } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';

const SettingUpdate = () => {
    const [setting, setSetting] = useState({});
    const { setting_id } = useParams();
    const navigate = useNavigate();
    const editor = useRef(null);

    const headers = {
        'x-access-token': localStorage.getItem('jwttoken') || "",
        'Content-Type': 'application/json', 
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchUser = async () => {
        try{
            const url = apiUrl + "settings/fetch/"+ setting_id;
            const response = await axios.get(url, { headers });

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                setSetting(response.data[0]);
            } else {
                setSetting({}); 
            }
        } catch (error) {
            console.error("Error fetching setting:", error);
            setSetting({}); 
        }
    };

    useEffect(() => {
        fetchUser();
    }, [setting_id]);

    const formValues = {
        setting_name: setting.setting_name || '',
        setting_value: setting.setting_value || '',
    };

    const updateSetting = async (values) => {
        const data = {
            setting_name: values.setting_name,
            setting_value: values.setting_value,
        };
    
        try {
            const url = `${apiUrl}settings/updatesetting/${setting_id}`;
            const response = await axios.put(url, data, { headers });

            if (response.status === 200) {
                alert("Setting Updated Successfully");
                navigate('/settinglist');
            } else {
                alert("Error: " + response.data.message);
            }
        } catch (error) {
            if (error.response) {
                console.error("Error updating setting:", error.response.data);
            } else if (error.request) {
                console.error("Request error:", error.request);
                alert("Error: No response from server");
            } else {
                console.error("Unexpected error:", error.message);
                alert("Error: " + error.message);
            }
        }
    };

    return (
        <div className="tp-faq__area pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8">
                        <div className="tab-content" id="nav-tabContent">
                            <div className='container mt-2 mb-3 grid'>
                                <h3 className='text-center'>Update Item</h3>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={formValues}
                                    onSubmit={(values) => updateSetting(values)}
                                >
                                    {({ setFieldValue }) => (
                                        <Form>
                                            <div>
                                                <label>Name: </label>
                                                <Field name="setting_name" type="text" required />
                                            </div>
                                            <div>
                                                <label>Value: </label>
                                                <JoditEditor
                                                    ref={editor}
                                                    value={formValues.setting_value}
                                                    onBlur={newContent => setFieldValue('setting_value', newContent)}
                                                    tabIndex={1} // tabIndex of textarea
                                                />
                                            </div>
                                            <div className='mt-3 mb-3'>
                                                <button type="submit" className='btn btn-success mx-2'>Update Item</button>
                                                <button type="button" className='btn btn-danger' onClick={() => navigate("/settinglist")}>Cancel</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingUpdate;