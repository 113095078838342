import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadImage from "../images/giphy.gif";

const notifydata = {
  position: "top-center",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
const successNotify = () => toast.success("User Activated!", notifydata);
const errNotify = () => toast.error("User not Activated!", notifydata);
const successDeactivateNotify = () =>
  toast.success("User Deactivated!", notifydata);
const errDeactivateNotify = () =>
  toast.error("User not Deactivated!", notifydata);
const deleteNotify = () =>
  toast.success("User deleted Successfully!", notifydata);
const errDeleteNotify = () => toast.error("User not Deleted!", notifydata);

const UsersList = () => {
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState("");

  const callApiExamsList = async () => {
    try {
      setLoader(true);
      const headers = {
        "x-access-token": localStorage.getItem("jwttoken"),
      };
      //console.log('headers', headers)
      const url = process.env.REACT_APP_API_URL + "users/allusers";
      const response = await axios.get(url, { headers });
      //console.log('response', response);
      setUserList(response.data);
      console.log("datta",response.data);
      
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    callApiExamsList();
  }, []);

  const deleteUser = async (id) => {
    const url = process.env.REACT_APP_API_URL + "users/removeuser/" + id;
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.delete(url, { headers });
      //console.log(response);
      deleteNotify();
    } catch (err) {
      errNotify();
    }
    callApiExamsList();
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const url = process.env.REACT_APP_API_URL + 'users/activate';
      const response = await axios.post(url,
        { userid: selectedUserId }, { headers });
      successNotify();
      callApiExamsList();
    } catch (err) {
      // console.error(err);
      errNotify();
    }
    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    errNotify();
    setShowModal(false);
  };

  const showActivateModal = (userid) => {
    // console.log(userid);
    setSelectedUserId(userid);
    setShowModal(true);
  };

  const handleDeactivateSubmit = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const url = process.env.REACT_APP_API_URL + 'users/deactivate';
      const response = await axios.post(url,
        { userid: selectedUserId }, { headers });
      successDeactivateNotify();
      callApiExamsList();
    } catch (err) {
      // console.error(err);
      errDeactivateNotify();
    }
    setShowDeactivateModal(false);
  };

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const handleDeactivateClose = () => {
    errDeactivateNotify();
    setShowDeactivateModal(false);
  };
  const showDeactivateModel = (userid) => {
    // console.log(userid);
    setSelectedUserId(userid);
    setShowDeactivateModal(true);
  };
  const [deleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const handleCancel = () => {
    setShow(false);
    errDeleteNotify();
    callApiExamsList();
  };
  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  const handleDeleteItem = () => {
    deleteUser(deleteId);
    setShow(false);
  };

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User ID</th>
                <th className="bg-warning-subtle">UserName</th>
                <th>Name</th>
                <th>Email</th>
                <th>Gender</th>
                <th>Mobile</th>
                <th className="bg-warning-subtle">Password</th>
                <th>UserType</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.map((item, index) => (
                  <tr key={index + item.exam_id}>
                    <td>{index + 1}</td>
                    <td>{item.userid}</td>
                    <td className="bg-warning-subtle">{item.username}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.gender}</td>
                    <td>{item.mobile}</td>
                    <td className="bg-warning-subtle">{item.password}</td>
                    <td>{item.usertype}</td>
                    <td>{item.status}</td>
                    <td>
                      <Link
                        to={`/useredit/${item.userid}`}
                        className="btn btn-warning"
                      >
                        Edit
                      </Link>
                    </td>
                    <td>
                      <button
                        onClick={() => showActivateModal(item.userid)}
                        className="btn btn-success"
                        disabled={item.status}
                      >
                        Activate User
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => showDeactivateModel(item.userid)}
                        className="btn btn-danger"
                        disabled={!item.status}
                      >
                        Deactivate User
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <br></br>
          <Link to="/useradd" className="btn btn-primary">
            Create New User
          </Link>
          <Modal show={showModal}>
            <Modal.Header>
              <Modal.Title>User Activate</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sure you want to activate user?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleSubmit}
                variant="primary"
                style={{ marginLeft: "10px" }}
              >
                Activate
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDeactivateModal}>
            <Modal.Header>
              <Modal.Title>User Deactivate</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sure you want to deactivate user?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeactivateClose}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleDeactivateSubmit}
                variant="primary"
                style={{ marginLeft: "10px" }}
              >
                Deactivate
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={show}>
            <Modal.Header>
              <Modal.Title>Confirm Delete?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sure you want to delete User ?</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleDeleteItem}>
                Yes
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default UsersList;
