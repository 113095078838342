
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from "formik";
import { useNavigate, useParams } from 'react-router-dom';
const _ = require('lodash');


const ClassUpdate = () => {
  const { classid } = useParams();
  //const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  var classDataInitialState = {
    classid: classid, courseid: "", classname: "", coursename: "", startdate: "", starttime: "", enddate: "",
    classlink: "", videolink: "", quizid: "", topicid: ""
  };
  const [updateData, setUpdateData] = useState(classDataInitialState);

  const callApiClassEdit = async (values) => {
    //setLoader(true);
    values.classid = classid;
    const url = process.env.REACT_APP_API_URL + 'classes/updateclass';
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.put(url, values, { headers });
      //console.log(response);
      //setLoader(false);
      navigate('/classlist');
    } catch (error) {
      console.log(error)
    }
  }
  const callApiClassDetails = async (classid) => {
    const url = process.env.REACT_APP_API_URL + 'classes/searchclass/' + classid;
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      setUpdateData(response.data[0]);
      console.log(response);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    (async () => {
      let data = await callApiClassDetails(classid);
      // SetUpdateData(data);
    })();
  }, [])

  useEffect(() => {

  }, [updateData])
  // console.log("updateData-",updateData);

  return (
    <>
      <h4 className='ClassAdd-heading'>Update Class</h4>
      <Formik
        enableReinitialize={true}
        initialValues={updateData}
        // initialValues={{ subject_id: "", question: "", answer1: "",  answer2: "",  answer3: "",  answer4: "",  answer5: "",  correctanswer: "" }}
        onSubmit={async (values) => {
          // await new Promise((resolve) => setTimeout(resolve, 500));
          // alert(JSON.stringify(values, null, 2));
          // eslint-disable-next-line no-restricted-globals
          if (_.isEqual(values, updateData)) {
            alert("No Updates made");
          }
          else {
            // eslint-disable-next-line no-restricted-globals
            var val = confirm("Sure you want to update class?");
            if (val === true) {
              alert("Updated successfully");
            } else {
              alert("Stopped Updating");
            }
          }
          // console.log(values);
          callApiClassEdit(values);
        }}
      >
        <div className='form-container'>
          <Form className='ClassAdd-form'>

            <br></br>

            <label>Class Name</label>
            <Field name="classname" type="text" />
            <br></br>
            <label>Course Name</label>
            <Field name="coursename" type="text" />
            <br></br>
            <label>Start Date</label>
            <Field name="startdate" type="date" />
            <br></br>
            <label>Start Time</label>
            <Field name="starttime" type="time" />
            <br></br>
            <label>End Date</label>
            <Field name="enddate" type="date" />
            <br></br>
            <label>Class Link</label>
            <Field name="classlink" type="link" />
            <br></br>
            <label>Video Link</label>
            <Field name="videolink" type="link" />
            <br></br>
            <label>Quiz Id</label>
            <Field name="quizid" type="number" />
            <br></br>
            <label>Topic Id</label>
            <Field name="topicid" type="text" />

            <br></br>
            <button type="submit" className='btn btn-success'>Update Class</button>

            <br></br>
          </Form>
        </div>
      </Formik>
    </>
  )
}

export default ClassUpdate

