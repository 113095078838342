import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AssignmentDetail = () => {

  const { assgid } = useParams();
  const [assgData, setAssgData] = useState({});

  const fetchBlogDetail = async (assgid) => {
    // console.log(assgid);
    const url = process.env.REACT_APP_API_URL + 'assignments/search/' + assgid;
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      //console.log(response.data[0]);
      setAssgData(response.data[0])
    }
    catch (err) {
      // console.log('Error In API Call - ', err) 
    }
  }


  useEffect(() => {
    fetchBlogDetail(assgid);
  }, [])

  useEffect(() => {
    fetchBlogDetail(assgid);
  }, [window.location.pathname])

  useEffect(() => {
    // console.log('categories - ', categories);
  })

  return (
    <div>
      <div className='row'>
        <div className='col-12 bg-info bg-opacity-10'>
          <h3>{assgData.taskname}</h3>
        </div>

        <div className='col-12 bg-light bg-opacity-10'>
          <br></br>
          {/* <div>{assgData.taskcontent}</div> */}
          <div dangerouslySetInnerHTML={{ __html: assgData.taskcontent }} />
        </div>
        <div className='col-12 bg-info bg-opacity-10'>
          <div>Assignment Status - Pending</div>
        </div>
        <div className="submitAssignment-my2">
          <Link className="btn btn-primary" to={`/assignmentsubmit/${assgid}`}>Submit </Link>
        </div>
      </div>
    </div>
  )
}

export default AssignmentDetail
