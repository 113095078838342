import React, { useState, useEffect } from 'react';
import axios from 'axios';
import loadImage from "../images/giphy.gif";

function MyAttendance() {
  const [loader, setLoader] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);

  // Fetch the userId from localStorage
  const userId = localStorage.getItem('userid');
  console.log('UserId from localStorage:', userId); // Log the userId for debugging

  // Fetch attendance data (including remarks) for the logged-in student
  useEffect(() => {
    if (userId) {
      fetchAttendanceRemarks(userId);
    }
  }, [userId]);

  const fetchAttendanceRemarks = async (userId) => {
    try {
      setLoader(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}attendance/attendanceRemarks`, { userId });
      console.log('Fetched attendance data:', response.data); // Debugging - Check what data is received
      setAttendanceData(response.data); // Update attendance data in state
      setLoader(false);
    } catch (error) {
     // console.error('Error fetching attendance remarks:', error);
      setLoader(false);
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
  
    // Add one day to the date
    date.setDate(date.getDate() + 1);
  
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getUTCFullYear();
    
    return `${day}/${month}/${year}`;
  };
  

  return (
    <>
      {loader ? (
        <img src={loadImage} alt="Loading..." />
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Sr.No</th>
              <th scope="col">DATE</th>
              <th scope="col">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData.length > 0 ? (
              attendanceData.map((record, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{formatDate(record.date)}</td>
                  <td>{record.remarks}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No attendance records found</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
}

export default MyAttendance;
