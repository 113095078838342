import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from "react-bootstrap";
import { format } from 'date-fns';
import loadImage from '../images/giphy.gif';

const notifydata = {
  position: "top-center",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}
const successNotify = () => toast.success("Assignment deleted Successfully!", notifydata);
const errNotify = () => toast.error("Assignment not deleted!", notifydata);
const AssignmentList = () => {
  const [assignmentList, setAssignmentList] = useState([]);
  const [loader, setLoader] = useState(true);
  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd.MM.yyyy');
  };

  const callApiAssignmentList = async () => {
    try {
      setLoader(true);
      const url = process.env.REACT_APP_API_URL + 'assignments/showalladmin';
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.get(url, { headers });
      setAssignmentList(response.data);
      setLoader(false);


    }
    catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  useEffect(() => {
    callApiAssignmentList();
  }, [])

  const deleteAssignment = async (id) => {
    if (id) {
      const url = process.env.REACT_APP_API_URL + 'assignments/delete/' + id;
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken')
      };
      const response = await axios.put(url, { headers });
      successNotify();
    } else {
      errNotify();
    }
    callApiAssignmentList();
  }
  const [deleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    errNotify();
    callApiAssignmentList();
  };
  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  const handleDeleteItem = () => {
    deleteAssignment(deleteId);
    setShow(false);
  };

  return (
    <div>
      {loader ?
        (<div className=" col-8 loading">
          <img src={loadImage} alt="loader" />
        </div>) : (
          <>

            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Assignment Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  assignmentList && assignmentList.map((item, index) => (

                    <tr key={index + item.task_id}>
                      <td>{index + 1}</td>
                      <td>{item.taskname}</td>
                      <td>{formatDate(item.startdate)}</td>
                      <td>{formatDate(item.enddate)}</td>
                      <td>
                        <Link to={`/assignmentsupdate/${item.taskid}`} className='btn btn-warning'>Edit</Link>

                      </td>
                      <td>
                        <button onClick={() => handleClickDelete(item.taskid)} className='btn btn-danger'>Delete</button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <Link to="/assignmentsadd" className='btn btn-primary'>Create New Assignmet</Link>
            <Link to="/assignmentanswerlist" className='btn btn-primary' style={{ marginLeft: '10px' }}>All Assignment Answers</Link>

            <br></br>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header>
                <Modal.Title>Confirm Delete?</Modal.Title>
              </Modal.Header>
              <Modal.Body>Sure you want to delete Assignment ?</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleDeleteItem}>
                  OK
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </>
        )
      }

    </div>
  )
}

export default AssignmentList
