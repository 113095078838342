import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

const Users_Registered_Details = () => {
  const location = useLocation();
  const postId = location.pathname.split('/').pop();
  const [userList, setUserList] = useState({});
  const [editUserList, setEditUserList] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const headers = {
          'x-access-token': localStorage.getItem('jwttoken')
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}course/userDetails/${postId}`, { headers });
        setUserList(response.data);
        console.log(response.data);
        setEditUserList(response.data);
         //Form the full image URL
         const fullImageUrl = `${process.env.REACT_APP_API_URL}${response.data.profile_image.replace(/\\/g, '/')}`;
         //console.log(fullImageUrl);
         setImageUrl(fullImageUrl);
         
      } catch (error) { 
        { }
    
      } 

    };

    fetchUserList();
  }, [postId]);

  function CV() {
    if (userList.uploadCV != null || userList.uploadCV != undefined)
      return "Resume have Uploaded";
    else {
      return "Resume have'not uploaded till now";
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditUserList({
      ...editUserList,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken'),
         //'Content-Type': 'application/json'
      };

      await axios.put(`${process.env.REACT_APP_API_URL}course/userDetails/${postId}`, editUserList, { headers });
      //console.log(editUserList); 
      setUserList(editUserList);    
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };
  const handleEdit = () => {
    // Ensure editUserList is synchronized with userList
    setEditUserList(userList); 
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditUserList(userList); 
    setIsEditing(false);
  };


  // this is for input fields
  const renderInputField = (label, name, type = 'text') => (
     <div className="mb-3">
      <label className="form-label"><strong>{label}:</strong></label>
      {isEditing ? (
        <input
          type={type}
          name={name}
          className="form-control"
          value={editUserList[name] || ''}
          onChange={handleInputChange}
        />
      ) : (
        <p className="card-text">{userList[name]}</p>
      )}
    </div> 
  );

  // file upload form
   const handleFileChange = (e) => {
      //console.log(e.target.files[0]);
      setSelectedFile(e.target.files[0]);
    };
  
    const handleFileUpload = async () => {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}course/userDetails/uploads/profile/${postId}`,
          formData,
          {
            headers: {
              'accept':'application/json',
              'Content-Type': 'multipart/form-data',
              'x-access-token': localStorage.getItem('jwttoken')
            }
          }
        );
        console.log('File upload response:', response.data);
        alert('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file');
      }
    };
   
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body btn btn-outline-info"  >
          <div className="card m-4 mb-4 p-4 " style={{ height: '100%' }}>
       
          {/*<p className="card-text"><strong>Username:</strong> {userList.username}</p>
            <p className="card-text"><strong>Name:</strong> {userList.name}</p>
            <p className="card-text"><strong>Email:</strong> {userList.email}</p>
            <p className="card-text"><strong>Mobile:</strong> {userList.mobile}</p>
            <p className="card-text"><strong>Date:</strong> {userList.date}</p>
            <p className="card-text"><strong>User Type:</strong> {userList.usertype}</p>
            <p className="card-text"><strong>User Status:</strong> {userList.user_status}</p>
            <hr />
            <p className="card-text"><strong>WhatsApp:</strong> {userList.whatsapp}</p>
            <p className="card-text"><strong>City:</strong> {userList.city}</p>
            <p className="card-text"><strong>Home Town :</strong> {userList.hometown}</p>
            <p className="card-text"><strong>College:</strong> {userList.college}</p>
            <p className="card-text"><strong>University:</strong> {userList.university}</p>
            <p className="card-text"><strong>Passing Year:</strong> {userList.passing_year}</p>
            <p className="card-text"><strong>Branch:</strong> {userList.branch}</p>
            <p className="card-text"><strong>LinkedIn:</strong> {userList.linkedinid}</p>
            <p className="card-text"><strong>GitHub:</strong> {userList.github}</p>
            <p className="card-text"><strong>Current Company:</strong> {userList.currentcompany}</p>
            <p className="card-text"><strong>Year Of Exprience:</strong> {userList.yearsOfExperience}</p>
            <p className="card-text"><strong>Current Designation:</strong> {userList.currentDesignation}</p>
            <p className="card-text"><strong>Notice Period:</strong> {userList.noticePeriod}</p>
            <p className="card-text"><strong>Current Salary:</strong> {userList.currentSalary}</p>
            <p className="card-text"><strong>Skills:</strong> {userList.skills}</p>
            <p className="card-text"><strong>Date of Birth:</strong> {userList.dob}</p>
            <p className="card-text"><strong>Work Location:</strong> {userList.workLocation}</p>
            <p className="card-text"><strong>Type Of Part:</strong> {userList.typePartFull}</p>
            <p className="card-text"><strong>Type Of Intern:</strong> {userList.typeFullIntern}</p>
            <p className="card-text"><strong>Job Type:</strong> {userList.jobType}</p>

            <p className="card-text"><strong>Resume:</strong> {CV()}</p>
            <p className="card-text"><strong>User Details Status:</strong> {userList.details_isactive}</p>

            <hr />
            <p className="card-text"><strong>User id:</strong> {userList.reg_courses_userid}</p>
            <p className="card-text"><strong>Course id:</strong> {userList.courseid}</p>
            <p className="card-text"><strong>Registration Date:</strong> {userList.regdate}</p>
            <p className="card-text"><strong>Referred By:</strong> {userList.referredby}</p>
            <p className="card-text"><strong>Status:</strong> {userList.reg_courses_regstatus}</p>
            <p className="card-text"><strong>Total Fee:</strong> {userList.totalfee}</p>
            <p className="card-text"><strong>Paid Fee:</strong> {userList.paidfee}</p>
            <p className="card-text"><strong>Due Fee:</strong> {userList.duefee}</p>
            <p className="card-text"><strong>Certificate Id:</strong> {userList.certificateid}</p>
            <p className="card-text"><strong>Grade:</strong> {userList.grade}</p>
            <p className="card-text"><strong>Certificate Deliver Status:</strong> {userList.certiDeliverStatus}</p>
            <p className="card-text"><strong>Completion Status:</strong> {userList.completionStatus}</p>*/}
            {renderInputField('Username', 'username')}
            {renderInputField('UserID', 'user_id')}
            {renderInputField('Name', 'name')}
            {renderInputField('Email', 'email', 'email')}
            {renderInputField('Mobile', 'mobile')}
            {renderInputField('PassWord', 'password')}
            {renderInputField('User Type', 'usertype')}
            {renderInputField('User Status', 'user_status')}
            {renderInputField('Registered_Courses', 'registered_courses')}
            <hr />
            {renderInputField('WhatsApp', 'whatsapp')}
            {renderInputField('Gender', 'gender')}
            {renderInputField('City', 'city')}
            {renderInputField('Home Town', 'hometown')}
            {renderInputField('College', 'college')}
            {renderInputField('University', 'university')}
            {renderInputField('Passing Year', 'passing_year')}
            {renderInputField('Branch', 'branch')}
            {renderInputField('LinkedIn', 'linkedinid')}
            {renderInputField('GitHub', 'github')}
            {renderInputField('Current Company', 'currentcompany')}
            {renderInputField('Years Of Experience', 'yearsOfExperience')}
            {renderInputField('Current Designation', 'currentDesignation')}
            {renderInputField('Notice Period', 'noticePeriod')}
            {renderInputField('Current Salary', 'currentSalary')}
            {renderInputField('Skills', 'skills')}
            {renderInputField('Date of Birth', 'dob')}
            {renderInputField('Work Location', 'workLocation')}
            {renderInputField('Type Of Part', 'typePartFull')}
            {renderInputField('Type Of Intern', 'typeFullIntern')}
            {renderInputField('Job Type', 'jobType')}
            <p className="card-text"><strong>Resume:</strong> {userList.uploadCV ? "Resume have Uploaded" : "Resume have'not uploaded till now"}</p>
            {renderInputField('User Details Status', 'details_isactive')}
            <hr />
            {renderInputField('Registration id', 'reg_courses_userid')}
            {renderInputField('Course id', 'courseid')}
            {renderInputField('Registration Date', 'regdate')}
            {renderInputField('Referred By', 'referredby')}
            {renderInputField('Status', 'reg_courses_regstatus')}
            {renderInputField('Total Fee', 'totalfee')}
            {renderInputField('Paid Fee', 'paidfee')}
            {renderInputField('Due Fee', 'duefee')}
            {renderInputField('Project', 'project')}
            {renderInputField('Start Date', 'startdate')}
            {renderInputField('End Date', 'enddate')}
            {renderInputField('Duration', 'duration')}
            {renderInputField('EmployeeID', 'employeeid')}
            {renderInputField('Certificate Id', 'certificateid')}
            {renderInputField('Document Status', 'docstatus')}
            {renderInputField('Attendance Status', 'attendancestatus')}
            {renderInputField('Fee_Status', 'feestatus')}
            {renderInputField('Exit Status', 'exitstatus')}
            {renderInputField('Grade', 'grade')}
            {renderInputField('Certificate Deliver Status', 'certiDeliverStatus')}
            {renderInputField('Completion Status', 'completionStatus')}
            {isEditing ? (
                <>
                  <button onClick={handleSave} className="btn btn-primary border border-dark mb-2">Save</button>
                  <button onClick={handleCancel} className="btn btn-secondary border border-dark mb-2">Cancel</button>
                </>
              ) : (
                <button onClick={handleEdit} className="btn btn-warning border border-dark mb-2">Edit</button>
              )}
            <hr/>
            <div>
                  <h2>File Upload Form</h2>
                  <input type="file" name="file" onChange={handleFileChange} />
                  <button onClick={handleFileUpload}>Upload File</button>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <h2>User Profile Photo</h2>
                {imageUrl ? (
                <img src={imageUrl} alt="UserImage" className="img-fluid rounded-circle border border-dark" style={{ width: '150px', height: '150px' }} />
                ) : (
                <p>No image available</p>
                )}
              </div>
            </div>
            </div>
         
        </div>
              
              <Link to="/users_registered" type="button" className="btn btn-warning ml-left">Cancel</Link>
        </div>
    </div>
  );
};

export default Users_Registered_Details;

