import React, { useState } from "react";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import loadImage from "../images/giphy.gif";

const MernRegister = () => {
  const [apistatus, setApistatus] = useState(false);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(true);

  const registerMern = async (values) => {
    try {
      //setLoader(true);
      //console.log(values);
      const url = process.env.REACT_APP_API_URL + "users/addusermern";
      //const url = 'http://jscmsapi.codeacetechnologies.com/users/adduser';

      const headers = {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("jwttoken"),
      };

      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: headers,
        body: JSON.stringify(values), // body data type must match "Content-Type" header
      });

      //const response = await axios.post(url, values);
      //console.log(response);

      if (response.status === 201) {
        setApistatus(true);
        //setLoader(false);
        return {
          error: false,
        };
      } else {
        setError(true);
        //setLoader(false);
        return {
          error: true,
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="row maincontent">
        <div className="col-2 bg-info bg-opacity-10"></div>
        <div className="col-8 bg-success bg-opacity-10">
          <h2 className="text-center">
            MERN Stack - Oct 2023 - Registration Form
          </h2>

          <div className="text-center">
            Please, fill the form. After registration, you will be sent login id
            and password on your email id by 4th October, 2023.
          </div>

          <Formik
            initialValues={{
              name: "",
              email: "",
              gender: "",
              mobile: "",
              whatsapp_mobile: "",
              city: "",
              hometown: "",
              college: "",
              university: "",
              passing_year: "",
              branch: "",
              linkedinid: "",
              referredby: "",
              islaptop: "",
              mern_knowledge: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              //console.log(values);
              const { error } = await registerMern(values);
              if (!error) {
                resetForm();
              }
            }}
          >
            <Form>
              <br></br>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">Name</label>
                <div className="col-sm-7">
                  <Field
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">Email</label>
                <div className="col-sm-7">
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter Gmail"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">Gender</label>
                <div className="col-sm-7">
                  <Field
                    name="gender"
                    as="select"
                    className="form-control"
                    required
                  >
                    <option value="" label="Select gender" />
                    <option value="Male" label="Male" />
                    <option value="Female" label="Female" />
                    <option value="Other" label="Other" />
                  </Field>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">Mobile</label>
                <div className="col-sm-7">
                  <Field
                    name="mobile"
                    type="text"
                    className="form-control"
                    placeholder="Enter Mobile No"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  WhatsApp Mobile
                </label>
                <div className="col-sm-7">
                  <Field
                    name="whatsapp_mobile"
                    type="text"
                    className="form-control"
                    placeholder="Enter WhatsaApp No"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Present Address
                </label>
                <div className="col-sm-7">
                  <Field
                    name="city"
                    type="text"
                    className="form-control"
                    placeholder="Enter Present Address"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Permanent Address
                </label>
                <div className="col-sm-7">
                  <Field
                    name="hometown"
                    type="text"
                    className="form-control"
                    placeholder="Enter Permanent Address"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">College</label>
                <div className="col-sm-7">
                  <Field
                    name="college"
                    type="text"
                    className="form-control"
                    placeholder="College Name"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">University</label>
                <div className="col-sm-7">
                  <Field
                    name="university"
                    type="text"
                    className="form-control"
                    placeholder="University Name"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">Passing Year</label>
                <div className="col-sm-7">
                  <Field
                    name="passing_year"
                    type="text"
                    className="form-control"
                    placeholder="College Passing Year"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Branch / Stream
                </label>
                <div className="col-sm-7">
                  <Field
                    name="branch"
                    type="text"
                    className="form-control"
                    Enter
                    placeholder="Branch/Stream like B Tech/BCA/MCA/M Tech and Branch"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Linkedin Profile URL
                </label>
                <div className="col-sm-7">
                  <Field
                    name="linkedinid"
                    type="text"
                    className="form-control"
                    placeholder="Your Linkedin Profile URL (if any)"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  Where did you hear about the course?
                </label>
                <div className="col-sm-7">
                  <Field
                    name="referredby"
                    type="text"
                    className="form-control"
                    placeholder="LinkedIn/Instagram/WhatsApp/Others - Name of Person who posted"
                  />
                </div>
              </div>

              <div className="form-group row">
                <span id="my-radio-group" className="col-sm-5 col-form-label">
                  Do you have laptop?
                </span>
                <div className="col-sm-7">
                  <span role="group" aria-labelledby="my-radio-group">
                    <label>
                      <Field
                        type="radio"
                        name="islaptop"
                        value="One"
                        className="form-check-input"
                      />
                      Yes
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="islaptop"
                        value="Two"
                        className="form-check-input"
                      />
                      No
                    </label>
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <span id="my-radio-group" className="col-sm-5 col-form-label">
                  Do you have prior knowledge of MERN?
                </span>
                <div className="col-sm-7">
                  <span role="group" aria-labelledby="my-radio-group">
                    <label>
                      <Field
                        type="radio"
                        name="mern_knowledge"
                        value="None Knowledge"
                        className="form-check-input"
                      />
                      None
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="mern_knowledge"
                        value="Basic"
                        className="form-check-input"
                      />
                      Basic
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="mern_knowledge"
                        value="Intermediate"
                        className="form-check-input"
                      />
                      Intermediate
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="mern_knowledge"
                        value="Expert"
                        className="form-check-input"
                      />
                      Expert
                    </label>
                  </span>
                </div>
              </div>

              <br></br>
              <button type="submit" className="btn btn-success">
                Register
              </button>

              <br></br>
              <br></br>
            </Form>
          </Formik>

          {apistatus && (
            <div className="alert alert-success" role="alert">
              You are successfully registered.
            </div>
          )}

          {error && (
            <div className="alert alert-danger" role="alert">
              Please, try after sometime or try again with correct values.
            </div>
          )}
        </div>

        <div className="col-2 bg-info bg-opacity-10"></div>
      </div>
    </div>
  );
};

export default MernRegister;
